import { changePassword } from '@/auth/endpoints';
import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import { getPasswordValidator } from '@/common/validators';
import Button from '@/design-system/v3/button';
import { GInput } from '@/design-system/v3/g-input';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PROFILE_PATH } from '@/web/routes';
import { BackToButton } from '../../extra-menu';
import { handleValidationError } from '@/common/utils';
import { useSnack } from '@/common/hooks/use-snack';

export const ChangePasswordView = () => {
  const { successMessage, errorMessage } = useSnack();
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid, isSubmitting },
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const passwordsValues = watch(['newPassword', 'confirmPassword']);
  const onSubmit = async (data: any) => {
    try {
      await changePassword(data.currentPassword, data.newPassword);
      successMessage('resetPassword.snack.success.title', { detailedMessage: 'resetPassword.snack.success.message' });
      reset({ currentPassword: '', newPassword: '', confirmPassword: '' });
    } catch (e) {
      handleValidationError(e, undefined, errorMessage);
    }
  };

  const content = (
    <div className="flex flex-col space-y-5">
      <GInput
        {...control.register('currentPassword', {
          ...getPasswordValidator(),
        })}
        type="password"
        error={errors.currentPassword?.message?.toString()}
        placeholder={t('authView.form.currentPassword.title')}
        title={t('authView.form.currentPassword.title')}
      />

      <GInput
        {...control.register('newPassword', {
          ...getPasswordValidator(),
        })}
        type="password"
        autoComplete="new-password"
        error={errors.newPassword?.message?.toString()}
        placeholder={t('authView.form.newPassword.title')}
        title={t('authView.form.newPassword.title')}
      />

      <GInput
        {...control.register('confirmPassword', {
          validate: (value) => {
            return passwordsValues[0] !== value ? t('authView.form.password.notMatch') : undefined;
          },
        })}
        type="password"
        autoComplete="new-password"
        error={errors.confirmPassword?.message?.toString()}
        placeholder={t('authView.form.password.confirm.title')}
        title={t('authView.form.password.confirm.title')}
      />

      <Button
        onClick={handleSubmit(onSubmit)}
        disabled={!isValid || passwordsValues[0] !== passwordsValues[1]}
        loading={isSubmitting}
        className="self-start"
      >
        {t('save')}
      </Button>
    </div>
  );

  return (
    <PageWrapper
      header={<PageHeader title={t('changePassword.title')} leftAction={<BackToButton path={PROFILE_PATH} />} />}
      contentClasses="py-4"
      content={content}
    />
  );
};
