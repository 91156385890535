import { useQuery } from 'react-query';
import { FETCH_CALENDARS, getCalendars } from '@/features/calender/api/CalendarApi';
import { useBusiness } from '@/web/hooks/use-business';
import { useMemo } from 'react';

export const useCalendars = () => {
  const { businessUuid } = useBusiness();
  const { data, isLoading } = useQuery([FETCH_CALENDARS, businessUuid], async () => {
    if (!businessUuid) return;

    return getCalendars(businessUuid);
  });

  return useMemo(
    () => ({
      calendars: data?.calendars ?? [],
      isLoading,
    }),
    [data, isLoading],
  );
};
