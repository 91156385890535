import { ProfileAvatar } from '@/web/components/profile-avatar';
import { useAuth } from '@/auth/hooks/use-auth';
import ChevronLargeRight from '@/common/components/icon/icons/v2/chevron-large-right.svg?react';
import { useNavigate } from 'react-router-dom';
import { getProfilePath, PERSONAL_INFO_PATH } from '@/web/routes';

export const ProfileHeader = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  return (
    <div className="px-4 pt-4">
      <button
        className="flex justify-between items-center p-4 w-full border border-gray-200 rounded-lg shadow-sm bg-white"
        onClick={() => navigate(getProfilePath(PERSONAL_INFO_PATH))}
      >
        <div className="flex items-center space-x-3">
          <ProfileAvatar className="w-[48px] h-[48px] border-none" />
          <div className="flex flex-col justify-center text-left text-typo-primary contrast:text-contrast-typo-primary">
            <h1 className="text-xl font-median -mb-1 mt-px">{user?.firstName}</h1>
            <span className="text-sm">{user?.email}</span>
          </div>
        </div>
        <ChevronLargeRight className="w-6 h-6 text-gray-400" />
      </button>
    </div>
  );
};
