import { useAuth } from '@/auth/hooks/use-auth';
import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import { StampsWidget } from '@/common/components/stamps-widget';
import { BackToButton } from '../../extra-menu';
import { PROFILE_PATH } from '@/web/routes';
import { useBusiness } from '@/web/hooks/use-business';
import { formatAmount } from '@/common/utils';

export const Subscriptions = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { businessUser } = useBusiness();

  const content = (
    <div>
      {user?.businesses.map((business) => {
        return (
          <div
            key={business.businessId}
            className="border-b dark:last:dark:border-b-gray-700 dark:only:dark:border-b-gray-700 w-full flex items-center justify-between p-4"
          >
            <div className="space-y-1 text-sm">
              <div>{business.businessName}</div>
              <div className="space-y-2">
                {business.memberships.map((businessMembership) => {
                  const businessUserMembership = businessUser?.activeMemberships?.find(
                    (m) => m.membership.id === businessMembership.membershipId,
                  );

                  if (!businessUserMembership) {
                    return;
                  }

                  const consumable = businessMembership.entries !== null && businessMembership.consumed !== null;

                  return (
                    <div key={businessMembership.membershipId}>
                      {businessUserMembership.membership.name} (
                      {businessUserMembership.amount !== businessUserMembership.fullAmount && (
                        <span className="mr-1 text-gray-400 line-through">
                          {formatAmount(businessUserMembership.fullAmount, 'EUR')}
                        </span>
                      )}
                      <span>{formatAmount(businessUserMembership.amount, 'EUR')}</span>)
                      {consumable && (
                        <div className="mt-1 mb-3">
                          <StampsWidget
                            total={businessMembership.entries || 0}
                            consumed={businessMembership.consumed || 0}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );

  return (
    <PageWrapper
      header={
        <PageHeader
          title={t('profileMenuView.subscriptions.title')}
          leftAction={<BackToButton path={PROFILE_PATH} />}
        />
      }
      contentClasses="p-0"
      content={content}
    />
  );
};
