import BoltIcon from '@/common/components/icon/icons/v2/bolt.svg?react';
import Button from '@/design-system/v3/button';
import { GPanel } from '@/design-system/v3/g-panel';
import { useEffect, useState } from 'react';
import { sendPirschEvent } from '@/analytics/utils';
import { IconWrapper } from '../../icon-wrapper';
import { Title } from '@/design-system/v3/title';
import Text from '@/design-system/v3/text';
import { RadioGroup } from '@headlessui/react';
import clsx from 'clsx';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getUserGoals, setUserGoals } from '@/web/endpoints';

const goals = [
  {
    label: 'Once a week',
    value: 1,
  },
  {
    label: 'Twice a week',
    value: 2,
  },
  {
    label: 'Three times a week',
    value: 3,
  },
  {
    label: 'Four times a week',
    value: 4,
  },
];

const FETCH_GOALS_QUERY_ID = 'USER_GOALS';
export const useGoals = () => {
  const { isFetching, data } = useQuery(
    [FETCH_GOALS_QUERY_ID],
    async () => {
      const resp = await getUserGoals();

      return resp;
    },
    {
      staleTime: Infinity,
    },
  );

  return { isFetching, data };
};

export const GoalsPanel = () => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<null | number>(null);
  const { isFetching, data } = useGoals();
  const noVisitsSet = data?.weeklyVisits === null;
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(async () => {
    await setUserGoals({ weeklyVisits: selected });
    queryClient.invalidateQueries(FETCH_GOALS_QUERY_ID);
    setOpen(false);
  });

  useEffect(() => {
    if (data?.weeklyVisits) {
      setSelected(data.weeklyVisits);
    }
  }, [data?.weeklyVisits]);

  if (isFetching) return null;

  return (
    <>
      <Button
        variant="secondary"
        gsize="large"
        className="text-black-100 dark:text-black-10"
        onClick={() => {
          setOpen(true);
          sendPirschEvent('Booking Flow Goals Panel', {
            page: 'booking-flow',
          });
        }}
      >
        {noVisitsSet ? 'Set your goal' : 'Edit your goal'}
      </Button>
      <GPanel title="What’s your goal?" open={open} onClose={() => setOpen(false)} className="px-0">
        <div className="flex flex-col justify-between h-full">
          <div className="flex flex-col grow overflow-auto">
            <div className="text-primary contrast:text-contrast-primary dark:text-black-10 my-4">
              <IconWrapper icon={BoltIcon} className="w-8 h-8" />
            </div>
            <Title variant="title3">Build a habit with streaks</Title>
            <Text variant="small" className="text-gray">
              Set, track and reach goals for breakfast. You level up for every successful week.
            </Text>
            <div>
              <RadioGroup defaultValue={selected} onChange={setSelected}>
                <RadioGroup.Label className="my-4 block text-black-100 dark:text-black-10">
                  How often do you plan to go to the gym?
                </RadioGroup.Label>
                <div>
                  {goals.map((goal) => (
                    <RadioGroup.Option
                      key={goal.value}
                      value={goal.value}
                      className="flex items-center gap-4 p-4 border-t last:border-b border-gray-200 dark:border-gray-700"
                    >
                      {({ checked }) => (
                        <>
                          <div className="flex items-center w-6 h-6 min-w-[24px] justify-center rounded-full bg-white dark:bg-black-100 ring-inset ring-1 ring-gray dark:ring-white">
                            {checked && (
                              <div className="w-2 h-2 min-h-[8px] bg-primary contrast:bg-contrast-primary dark:bg-white rounded-full" />
                            )}
                          </div>
                          <div
                            className={clsx(
                              'dark:text-black-10 text-black-100',
                              checked ? 'font-semibold' : 'font-normal',
                            )}
                          >
                            {goal.label}
                          </div>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
              <Text variant="small" className="text-gray py-4">
                Don’t worry! You can always adjust your goals.
              </Text>
            </div>
          </div>
          <div className="pt-4">
            <Button
              loading={isLoading}
              gsize="large"
              onClick={() => {
                mutate();
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </GPanel>
    </>
  );
};
