import { LocationSelect } from '@/business/components/location-select';
import { MembershipSelect } from '@/business/components/membership-select';
import { signupAtBusiness } from '@/business/endpoints';
import { useBusiness } from '@/web/hooks/use-business';
import { getRequiredValidator } from '@/common/validators';
import { Alert, AlertDesign, AlertVariant } from '@/design-system/v3/alert';
import { Controller, useForm } from 'react-hook-form';
import { OnboardingStepsLayout } from '../onboarding-steps-layout';
import { useSnack } from '@/common/hooks/use-snack';
import { OnboardingFormContext, OnboardingFormData } from '..';
import { OnboardingFooter } from '../onboarding-footer';
import { OnboardingTransition } from '../onboarding-transition';
import { formatAmount, handleValidationError, removeFromStorage } from '@/common/utils';
import {
  ONBOARDING_BUSINESS_ID,
  ONBOARDING_BUSINESS_LOCATION_ID,
  ONBOARDING_BUSINESS_MEMBERSHIP_ID,
} from '@/common/constants';
import { useContext, useMemo, useState } from 'react';
import { useProrateResult } from '../onboarding-choices';
import { useTranslation } from 'react-i18next';
import { GCheckbox } from '@/design-system/v3/g-checkbox';
import { login, signup } from '@/auth/endpoints';
import { getDecodedToken } from '@/auth/utils';
import { ErrorCode } from '@/common/types';
import { User } from '@/auth/types';
import { updateUser } from '@/web/endpoints';
import { useSetRecoilState } from 'recoil';
import { authJWTState, authTokenSelector, authUserState } from '@/auth/atoms';
import { useAuth } from '@/auth/hooks/use-auth';
import { MembershipPaymentType } from '@/web/types';
import { BusinessTerms } from '../../../../common/components/business-terms';
import { GInput } from '@/design-system/v3/g-input';
import clsx from 'clsx';
import { useDebounce } from '@/common/hooks/use-debounce';

export interface FormData {
  membershipId: string;
  businessLocationId: string;
  acceptTermsAndConditions: boolean;
  discountCode: string | null;
}

interface Props {
  onPreviousStep: () => void;
  isNewUser: boolean;
}

export const OnboardingReviewForm = ({ onPreviousStep, isNewUser }: Props) => {
  const { t } = useTranslation();
  const { successMessage, errorMessage } = useSnack();
  const { primaryLocationId, membershipId } = useContext(OnboardingFormContext);
  const { businessUuid, businessMemberships, businessFeatures } = useBusiness();
  const { user } = useAuth();
  const membership = businessMemberships?.find((mem) => mem.id === membershipId);
  const setAuthToken = useSetRecoilState(authTokenSelector);
  const setAuthUser = useSetRecoilState(authUserState);
  const setAuthJWT = useSetRecoilState(authJWTState);
  const [transitionDisabled, setTransitionDisabled] = useState<boolean>(false);
  const onboardingForm = useContext(OnboardingFormContext);
  const {
    handleSubmit,
    control,
    setError,
    formState: { isValid, isSubmitting },
    watch,
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      membershipId,
      businessLocationId: primaryLocationId,
      acceptTermsAndConditions: false,
      discountCode: null,
    },
  });

  const discountCode = watch('discountCode');

  const hasUserMembershipAtBusiness = useMemo(() => {
    const hasMemberhipAtBusiness =
      businessUuid && !!user?.businesses?.find((bus) => bus.businessId === businessUuid && bus.memberships.length > 0);

    return hasMemberhipAtBusiness && !isNewUser;
  }, [user, businessUuid, isNewUser]);

  const isFree = membership?.amount === 0;
  const isOncePayment = membership?.paymentType === MembershipPaymentType.ONCE;

  const debouncedDiscountCode = useDebounce(discountCode, 500);
  const { prorateResult } = useProrateResult({
    locationId: primaryLocationId,
    membershipId,
    discountCode: debouncedDiscountCode,
  });

  const handleOnboardingFormSubmit = async (data: OnboardingFormData) => {
    if (!user?.email) {
      try {
        await signup(data.email, data.password);
        const jwtResponse = await login(data.email, data.password);

        setTransitionDisabled(true);
        setAuthToken(jwtResponse.jwt);
        setAuthJWT(getDecodedToken());
      } catch (e) {
        const response = e.response?.data;

        if (response && response.code === ErrorCode.ACCOUNT_EMAIL_ALREADY_IN_USE) {
          onboardingForm.setError('email', { message: t('emailAlreadyRegistered') });
          onPreviousStep();
        }

        throw e;
      }
    }

    const newUser = await updateUser({
      firstName: data.firstName,
      lastName: data.lastName,
      dateOfBirth: data.dateOfBirth,
      phoneNumber: data.phoneNumber,
      address:
        data.street || data.city || data.postalCode || data.country
          ? {
              street: data.street,
              city: data.city,
              postalCode: data.postalCode,
              country: data.country,
            }
          : null,
    } as User);

    setTransitionDisabled(true);
    setAuthUser(newUser);
  };

  const onSubmit = async (data: FormData) => {
    if (!businessUuid) {
      return;
    }

    if (!user) {
      try {
        await onboardingForm.handleSubmit(handleOnboardingFormSubmit)();
      } catch (e) {
        handleValidationError(e, onboardingForm.setError, errorMessage);
        return;
      }
    }

    try {
      const signupResponse = await signupAtBusiness(businessUuid, data);

      successMessage('signUp.succesfull', {
        detailedMessage: isFree ? 'onboarding.review.redirectToApp' : 'onboarding.review.redirectToPayment',
      });

      // Cleanup storage.
      removeFromStorage(ONBOARDING_BUSINESS_ID);
      removeFromStorage(ONBOARDING_BUSINESS_LOCATION_ID);
      removeFromStorage(ONBOARDING_BUSINESS_MEMBERSHIP_ID);

      // Redirect.
      window.location.href = signupResponse.redirectUrl;

      return null;
    } catch (e: unknown) {
      handleValidationError(e, setError, errorMessage);
    }
  };

  return (
    <OnboardingStepsLayout
      title={
        isNewUser ? (
          t('onboarding.review.title')
        ) : (
          <div className="flex flex-col">
            <span>
              {t('welcomeBack')} {user?.firstName},
            </span>
            <span>{t('onboarding.review.title')}</span>
          </div>
        )
      }
    >
      {businessFeatures?.includes('Discount') && (
        <div className="flex flex-col space-y-2 w-full mb-6">
          <GInput
            {...control.register('discountCode')}
            title="Discount code"
            iconContainerClasses="w-full"
            className={clsx({
              'ring-2 ring-typo-positive focus:ring-typo-positive focus:border-typo-positive':
                prorateResult?.discountAmount !== null && prorateResult?.discountAmount !== undefined,
              'ring-2 ring-typo-negative focus:ring-typo-negative focus:border-typo-negative':
                debouncedDiscountCode && prorateResult && prorateResult.discountAmount === null,
            })}
          />
          {debouncedDiscountCode && prorateResult && prorateResult.discountAmount === null && (
            <div className="text-typo-negative">{t('invalidDiscountCode')}</div>
          )}
        </div>
      )}
      <OnboardingTransition disabled={transitionDisabled}>
        <div className="my-4 space-y-3">
          {hasUserMembershipAtBusiness && (
            <Alert
              design={AlertDesign.BORDERED}
              variant={AlertVariant.INFO}
              title={t('onboarding.review.recurring.membership.title')}
              content={t('onboarding.review.recurring.membership.description')}
            />
          )}

          {membership?.registrationFee && (
            <Alert
              design={AlertDesign.BORDERED}
              content={t('onboarding.review.registrationFee', {
                amount: formatAmount(membership.registrationFee),
              })}
            />
          )}

          {prorateResult && membership && membership.paymentType === MembershipPaymentType.PERIODIC && (
            <Alert
              design={AlertDesign.BORDERED}
              content={
                <span>
                  {t('onboarding.review.prorateInfo', {
                    fullAmount: formatAmount(prorateResult.fullAmount),
                    daysLeft: prorateResult.daysLeft,
                    amount: formatAmount(prorateResult.amount),
                  })}
                </span>
              }
            />
          )}
          {!(isFree || isOncePayment) && (
            <Alert design={AlertDesign.BORDERED} content={t('onboarding.review.paymentAlert')} />
          )}

          <div>
            <div className="flex flex-row space-x-3 mt-4">
              <GCheckbox
                {...control.register('acceptTermsAndConditions', {
                  required: true,
                })}
              />
              <span>
                <BusinessTerms acknowledge />
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-3">
          <Controller
            name={'businessLocationId'}
            control={control}
            rules={getRequiredValidator()}
            render={({ field: { onChange, value } }) => (
              <LocationSelect className="hidden" value={value} onChange={onChange} placeholder={t('selectLocation')} />
            )}
          />

          <Controller
            name={'membershipId'}
            control={control}
            rules={getRequiredValidator()}
            render={({ field: { onChange, value } }) => (
              <MembershipSelect
                className="hidden"
                value={value || null}
                onChange={onChange}
                placeholder={t('selectMembership')}
              />
            )}
          />
          <div>
            <OnboardingFooter
              onPreviousStep={onPreviousStep}
              previousTitle={isNewUser ? t('previousStep') : t('signOut')}
              disabled={!isValid}
              loading={isSubmitting}
              onNextStep={handleSubmit(onSubmit)}
              nextTitle={t('confirmAndPay')}
            />
          </div>
        </div>
      </OnboardingTransition>
    </OnboardingStepsLayout>
  );
};
