import { useBusiness } from '@/web/hooks/use-business';
import { useQuery, useQueryClient } from 'react-query';
import { getUserAvailableMemberships } from '@/business/endpoints';
import { MembershipDescription } from '@/common/components/membership-description';
import { GAvatar } from '@/design-system/g-avatar';
import ChevronRight from '@/common/components/icon/icons/v2/chevron-right.svg?react';
import { useNavigate } from 'react-router-dom';
import { CHECKOUT_PATH } from '@/web/routes';
import { PullToRefresh } from '@/common/components/pull-to-refresh';
import { useCallback } from 'react';
import { useUserAvailableMemberships } from '@/web/views/shop/hooks/use-user-available-memberships';

export const FETCH_USER_AVAILABLE_MEMBERSHIPS = 'FETCH_USER_AVAILABLE_MEMBERSHIPS';

export const MembershipsTab = () => {
  const { businessUuid, businessLogo, businessIcon } = useBusiness();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { memberships } = useUserAvailableMemberships();

  const handleMembershipChange = (newMembershipId: string) => {
    navigate(`${CHECKOUT_PATH}/membership/${newMembershipId}`);
  };

  const handleRefresh = useCallback(async () => {
    return queryClient.invalidateQueries([FETCH_USER_AVAILABLE_MEMBERSHIPS, businessUuid]);
  }, [queryClient, businessUuid]);

  return (
    <PullToRefresh onRefresh={handleRefresh}>
      {memberships.map((membership) => (
        <button
          key={membership.id}
          className="flex flex-row text-left justify-between items-center space-x-4 py-5 px-4 w-full transition-all hover:bg-gray-50 border-b border-borders-secondary contrast:border-contrast-borders-secondary"
          onClick={() => handleMembershipChange(membership.id)}
        >
          <div className="flex flex-row items-start space-x-4">
            <div className="flex items-center justify-center w-12 h-12 min-w-12 rounded-md bg-gray-50">
              <GAvatar src={businessIcon || businessLogo || undefined} size="xs" className="rounded-xl" />
            </div>
            <div className="flex flex-col space-y-1">
              <div className="text-typo-primary contrast:text-contrast-typo-primary font-semibold">
                {membership.name}
              </div>
              <div className="text-typo-secondary contrast:text-contrast-typo-secondary">
                <MembershipDescription membership={membership} />
              </div>
            </div>
          </div>
          <div className="flex whitespace-nowrap justify-end items-center text-typo-primary contrast:text-contrast-typo-primary space-x-2">
            <div>{membership.amountFormatted}</div>
            <ChevronRight />
          </div>
        </button>
      ))}
    </PullToRefresh>
  );
};
