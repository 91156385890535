import DatePicker, { DatePickerProps } from 'react-datepicker';
import './styles.scss';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GInput } from '../g-input';

import { format } from 'date-fns';
import { CalendarBlank } from 'phosphor-react';
import { getLocaleObject } from '@/i18n/utils';

export interface DatepickerProps extends Omit<DatePickerProps, 'value'> {
  title?: string;
  error?: string | boolean;
  value: string;
  displayFormat?: string;
  helperText?: ReactNode;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  onChange: (e: any) => void;
  optionalLabel?: boolean;
}

export const GDatepicker = ({
  title,
  helperText,
  placeholder,
  error,
  required,
  optionalLabel,
  displayFormat = 'dd/MM/yyyy',
  disabled,
  onChange,
  value,
  ...rest
}: DatepickerProps) => {
  const [open, setOpen] = useState(false);
  const [inputVal, setInputVal] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (value) {
      setInputVal(format(new Date(value), displayFormat));
    }
  }, [value, displayFormat]);

  const onDatepickerChange = useCallback(
    (date: Date | null) => {
      if (!date) {
        onChange({
          target: { value: '' },
        });
        setInputVal('');
        return;
      }

      const formatted = format(date, displayFormat);
      setInputVal(formatted);
      onChange({
        target: {
          value: formatted,
        },
      });
    },
    [onChange, displayFormat],
  );

  const selectedDate = useMemo(() => {
    try {
      return value ? new Date(value) : null;
    } catch {
      return null;
    }
  }, [value]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.readOnly = true;
    }
  }, [inputRef]);

  return (
    <DatePicker
      onChange={onDatepickerChange}
      dateFormat={displayFormat}
      open={open}
      onCalendarOpen={() => setOpen(true)}
      onCalendarClose={() => setOpen(false)}
      disabled={disabled}
      value={inputVal}
      selected={selectedDate}
      locale={getLocaleObject()}
      showPopperArrow={false}
      customInput={
        // This div is necessary to prevent passing the ref to the input.
        <div>
          <GInput
            ref={inputRef}
            icon={<CalendarBlank className="w-5 h-5" />}
            iconContainerClasses={
              open
                ? 'shadow-inputFocus border-primary-300 contrast:border-contrast-primary-300'
                : !disabled
                  ? 'cursor-pointer'
                  : undefined
            }
            helperText={helperText}
            gtitle={title}
            placeholder={placeholder ?? displayFormat}
            required={required}
            error={error}
            value={inputVal}
            disabled={disabled}
            optionalLabel={optionalLabel || false}
          />
        </div>
      }
      {...rest}
    />
  );
};
