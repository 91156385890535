import { MembershipDescription } from '@/common/components/membership-description';
import { MembershipPaymentType, PaymentStatus } from '@/web/types';
import { useTranslation } from 'react-i18next';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useBusiness } from '@/web/hooks/use-business';
import SpinnerIcon from '@/common/components/icon/icons/v2/spinner.svg?react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { getPaymentStatus, startUserMembershipPurchase } from '@/business/endpoints';
import { formatAmount, handleValidationError, isApp } from '@/common/utils';
import { useNavigate, useParams } from 'react-router-dom';
import { NotFound } from '@/common/views/not-found';
import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import { BackToButton } from '@/web/views/extra-menu';
import { getProfilePath, MEMBERSHIPS_PATH, SHOP_PATH } from '@/web/routes';
import clsx from 'clsx';
import { GSwitch } from '@/design-system/v3/g-switch';
import { SafeHtml } from '@/common/components/safe-html';
import { ProrateResult } from '@/auth/types';
import { getProrateAmount } from '@/auth/endpoints';
import BasicDatePicker from '@/common/components/basic-datepicker';
import { parse } from 'date-fns';
import { useSnack } from '@/common/hooks/use-snack';
import Button from '@/design-system/v3/button';
import { UserPaymentMethods } from '@/common/components/user-payment-methods';
import { BusinessTerms } from '../../../common/components/business-terms';
import { useUserAvailableMemberships } from '@/web/views/shop/hooks/use-user-available-memberships';
import { FETCH_BUSINESS_USER_MEMBERSHIPS } from '@/features/memberships/api/BusinessUserMembershipApi';

const MEMBERSHIP_CHECKOUT_STATUS = 'MEMBERSHIP_CHECKOUT_STATUS';

export const MembershipCheckout = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { businessUuid, primaryLocationId } = useBusiness();
  const queryClient = useQueryClient();
  const { membershipsById } = useUserAvailableMemberships();
  const { errorMessage } = useSnack();
  const [proratedAmount, setProratedAmount] = useState<ProrateResult | null>(null);
  const [acknowledgeTermsConditionsPrivacy, setAcknowledgeTermsConditionsPrivacy] = useState<boolean>(false);
  const [customStartDate, setCustomStartDate] = useState<Date>(new Date());
  const [customStartDateEnabled, setCustomStartDateEnabled] = useState<boolean>(false);
  const [paymentToken, setPaymentToken] = useState<string | null>(null);
  const [paymentLink, setPaymentLink] = useState<string | null>(null);
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus | null>(null);

  const membership = useMemo(() => id && membershipsById[id], [membershipsById, id]);

  useQuery(
    MEMBERSHIP_CHECKOUT_STATUS,
    async () => {
      if (!paymentToken) {
        return;
      }

      const response = await getPaymentStatus(paymentToken);
      setPaymentStatus(response.status);

      if (response.status === PaymentStatus.SUCCESS) {
        await queryClient.invalidateQueries(FETCH_BUSINESS_USER_MEMBERSHIPS);
      }
    },
    {
      enabled: paymentToken !== null && paymentStatus === PaymentStatus.PENDING,
      refetchInterval: 2000,
    },
  );

  const { mutate: startPurchase, isLoading } = useMutation(
    async () => {
      if (!businessUuid || !membership) {
        return null;
      }

      let response;

      try {
        response = await startUserMembershipPurchase(
          businessUuid,
          membership.id,
          customStartDateEnabled ? customStartDate : null,
        );
      } catch (e) {
        handleValidationError(e, undefined, errorMessage);
        return;
      }

      setPaymentLink(response.paymentUrl);
      setPaymentStatus(PaymentStatus.PENDING);

      if (isApp()) {
        window.open(response.paymentUrl, '_self');
      } else {
        // Trick to open in a new tab without popup blocker.
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.href = response.paymentUrl;
        document.body.appendChild(link);
        link.click();
        link.remove();
      }

      setPaymentToken(response.paymentStatusToken);
    },
    {
      onSuccess: () => {
        // On success
      },
    },
  );

  useEffect(() => {
    if (
      !membership ||
      !businessUuid ||
      !primaryLocationId ||
      membership.paymentType !== MembershipPaymentType.PERIODIC
    ) {
      return;
    }

    (async () => {
      const result = await getProrateAmount(
        businessUuid,
        primaryLocationId,
        membership.id,
        customStartDateEnabled ? customStartDate : null,
      );
      setProratedAmount(result);
    })();
  }, [businessUuid, primaryLocationId, membership, customStartDateEnabled, customStartDate]);

  const handleCustomStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomStartDate(parse(e.target.value, 'dd-MM-yyyy', new Date()));
  };

  if (!membership) {
    return <NotFound />;
  }

  return (
    <PageWrapper
      header={<PageHeader title={t('checkout')} leftAction={<BackToButton path={SHOP_PATH} />} />}
      content={
        <div
          className={clsx('py-4', {
            'flex items-center justify-center h-full': !!paymentStatus,
          })}
        >
          {paymentStatus === null && (
            <div>
              <div className="flex flex-col pb-5">
                <div className="flex flex-col space-y-2">
                  <div className="text-typo-primary font-median text-xl">{membership.name}</div>
                  <div className="text-typo-secondary">
                    <MembershipDescription membership={membership} />
                  </div>
                  <div className="text-secondary">{formatAmount(membership.amount, 'EUR')}</div>
                </div>
                {membership.description && (
                  <div className="text-typo-primary text-lg mt-4">
                    <SafeHtml html={membership.description.replace(/\n/g, '<br />')} />
                  </div>
                )}
              </div>
              {(membership.paymentType === MembershipPaymentType.PERIODIC ||
                membership.paymentType === MembershipPaymentType.YEARLY) && (
                <CheckSection
                  id="custom-start-date"
                  label={
                    <div>
                      <div className="font-semibold text-typo-primary">{t('membershipCustomStartDate')}</div>
                      <div className="text-typo-secondary">{t('membershipCustomStartDateDescription')}</div>
                    </div>
                  }
                  checked={customStartDateEnabled}
                  onChange={(e) => {
                    setCustomStartDateEnabled(e.target.checked);
                  }}
                  content={
                    <div>
                      <BasicDatePicker
                        minDate={new Date()}
                        value={customStartDate.toISOString()}
                        onChange={handleCustomStartDateChange}
                        displayFormat="dd-MM-yyyy"
                        withPortal={true}
                      />
                    </div>
                  }
                />
              )}
              <CheckSection
                id="acknowledge-terms-conditions-privacy"
                label={<BusinessTerms acknowledge />}
                checked={acknowledgeTermsConditionsPrivacy}
                onChange={(e) => {
                  setAcknowledgeTermsConditionsPrivacy(e.target.checked);
                }}
              />
              {proratedAmount && (
                <div className="text-sm text-typo-secondary p-4 -mx-4 border-b border-borders-secondary">
                  {t('membershipProratedAmountHelper', {
                    count: proratedAmount.daysLeft,
                    amount: formatAmount(membership.amount, 'EUR'),
                    proratedAmount: formatAmount(proratedAmount.amount, 'EUR'),
                    days: proratedAmount.daysLeft,
                  })}
                </div>
              )}
              {membership.paymentType === MembershipPaymentType.PERIODIC && (
                <div className="text-xs text-typo-secondary p-4 -mx-4 border-b border-borders-secondary">
                  {t('authorizeRecurringPayment')}
                </div>
              )}
            </div>
          )}
          {paymentStatus && (
            <div className="flex flex-col items-center space-y-5 py-5">
              <div className="font-medium text-typo-primary text-base">
                {paymentStatus === PaymentStatus.PENDING && t('paymentInProgress')}
                {paymentStatus === PaymentStatus.SUCCESS && t('paymentComplete')}
              </div>
              {paymentStatus === PaymentStatus.PENDING && <SpinnerIcon className="w-16 h-16 animate-spin" />}
              {paymentStatus === PaymentStatus.SUCCESS && <CheckCircleIcon className="w-16 h-16 text-typo-positive" />}
              <div className="text-base text-typo-secondary">
                {paymentStatus === PaymentStatus.PENDING && isApp() && t('paymentWaitingConfirmation')}
                {paymentStatus === PaymentStatus.PENDING && !isApp() && paymentLink && (
                  <div className="text-center">
                    {t('redirectFallback.text')}{' '}
                    <a href={paymentLink} target="_blank" rel="noreferrer noopener" className="underline">
                      {t('redirectFallback.action')}
                    </a>
                    .
                  </div>
                )}
                {paymentStatus === PaymentStatus.SUCCESS && t('paymentConfirmationMail')}
              </div>
            </div>
          )}
        </div>
      }
      footer={
        <div className="flex flex-col items-center space-y-2.5 px-4 pt-4">
          {paymentStatus === PaymentStatus.SUCCESS && (
            <Button variant="primary" onClick={() => navigate(getProfilePath(MEMBERSHIPS_PATH))} fullWidth>
              {t('viewMemberships')}
            </Button>
          )}
          {!paymentToken && (
            <>
              <Button
                variant="primary"
                onClick={startPurchase}
                loading={isLoading || (paymentStatus && paymentStatus === PaymentStatus.PENDING)}
                disabled={!acknowledgeTermsConditionsPrivacy}
                fullWidth
              >
                {t('confirmAndPay')}
              </Button>
              <UserPaymentMethods />
            </>
          )}
        </div>
      }
    />
  );
};

const CheckSection = ({
  id,
  label,
  checked,
  onChange,
  content,
}: {
  id: string;
  label: string | ReactNode;
  checked: boolean;
  content?: ReactNode;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}) => {
  return (
    <div
      className={clsx(
        'relative flex flex-col justify-center space-y-5 px-4 py-5 -mx-4 border-t border-b border-borders-secondary contrast:border-contrast-borders-secondary',
        {
          'bg-surface-secondary contrast:bg-contrast-surface-secondary': checked,
        },
      )}
    >
      <div className="relative flex flex-row items-center justify-between space-x-4">
        <div className="text-typo-secondary contrast:text-contrast-typo-secondary">{label}</div>
        <div>
          <label htmlFor={id}>
            <GSwitch id={id} checked={checked} onChange={onChange} />
          </label>
        </div>
      </div>
      {checked && !!content && <div className="py-2">{content}</div>}
    </div>
  );
};
