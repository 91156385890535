import { CustomContentProps, SnackbarAction, SnackbarKey } from 'notistack';
import { forwardRef, FunctionComponent, ReactElement, ReactNode, SVGProps } from 'react';
import CheckCircle from '@/common/components/icon/icons/v2/check-circle.svg?react';
import WarningIcon from '@/common/components/icon/icons/v2/warning.svg?react';
import DangerIcon from '@/common/components/icon/icons/v2/danger.svg?react';
import clsx from 'clsx';
import { X } from 'phosphor-react';
import { useSnack } from '@/common/hooks/use-snack';
import Button from '@/design-system/v3/button';

export interface NotificationProps extends CustomContentProps {
  detailedMessage?: string;
  button?:
    | {
        icon: FunctionComponent;
        onClick: () => void;
      }
    | null
    | undefined;
  icon?: ReactNode;
}

const getActionTemplate = (action: SnackbarAction, id: SnackbarKey) => {
  if (typeof action === 'function') return action(id);

  return action || null;
};

export const GNotification = forwardRef<HTMLDivElement, NotificationProps>((props, ref) => {
  const { closeMessage } = useSnack();
  const { variant, message, detailedMessage, button, persist, action, id, icon: customIcon } = props;

  const ButtonIcon = button?.icon;
  let Icon: FunctionComponent<SVGProps<SVGSVGElement>> | null = null;
  const actionButton = getActionTemplate(action, id);

  switch (variant) {
    case 'success':
      Icon = () => <CheckCircle className="w-6 h-6 text-typo-positive contrast:text-contrast-typo-positive" />;
      break;
    case 'warning':
      Icon = WarningIcon;
      break;
    case 'error':
      Icon = DangerIcon;
      break;
    case 'default':
    default:
      Icon = null;
  }

  const iconClassName = clsx('relative z-10 w-6 h-6', {
    'text-success-600': variant === 'success',
    'text-warning-600': variant === 'warning',
    'text-error-600': variant === 'error',
  });

  return (
    <div
      ref={ref}
      className="flex items-center absolute bottom-0 w-full py-4 px-3 rounded-lg bg-white border border-borders-primary contrast:border-contrast-borders-primary"
      style={{ boxShadow: '0 2px 4px -2px rgba(16, 24, 40, 0.06), 0 4px 8px -2px rgba(16, 24, 40, 0.1)' }}
      role="alert"
    >
      <div className="flex justify-between w-full">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center">
            {(Icon || customIcon) && (
              <div className="relative flex justify-center items-center mr-3">
                {customIcon ? customIcon : Icon ? <Icon className={iconClassName} /> : null}
              </div>
            )}
            <div className="max-w-[275px]">
              <span className="flex text-typo-primary contrast:text-contrast-typo-primary">{message}</span>
              {detailedMessage && (
                <span className="text-typo-secondary contrast:text-contrast-typo-secondary">{detailedMessage}</span>
              )}
            </div>
            {button && ButtonIcon && (
              <div className="relative flex justify-center items-center ml-3">
                <Button className="h-12 w-12" variant="secondary" gsize="small" onClick={button.onClick} icon>
                  <ButtonIcon />
                </Button>
              </div>
            )}
          </div>
          {actionButton && <div>{actionButton}</div>}
        </div>
        {persist && !actionButton && (
          <button className="w-6 h-6" onClick={() => closeMessage(id)}>
            <X />
          </button>
        )}
      </div>
    </div>
  );
});

GNotification.displayName = 'GNotification';
