import { MembershipConsumptionDetails } from '@/auth/types';
import { lowerCaseFirstLetter } from '@/common/utils';
import { GProgressBar } from '@/design-system/v3/g-progress-bar/GProgressBar';
import { MembershipEntriesControl } from '@/web/types';
import React from 'react';
import { useTranslation } from 'react-i18next';

type MembershipDetailsProps = {
  details: MembershipConsumptionDetails;
};

const MembershipStampsDetail: React.FC<MembershipDetailsProps> = ({ details }) => {
  const { t } = useTranslation();
  const percentage = (details.consumed / details.limit) * 100;

  return (
    <div className="space-y-2">
      <GProgressBar size="sm" percentage={percentage} />

      <div className="flex justify-between items-center text-typo-tertiary text-sm">
        <span>
          {details.consumed}/{details.limit}
          {details.type !== MembershipEntriesControl.FIXED_AMOUNT && ' '}
          {details.type === MembershipEntriesControl.DAILY && lowerCaseFirstLetter(t('perDay'))}
          {details.type === MembershipEntriesControl.WEEKLY && lowerCaseFirstLetter(t('perWeek'))}
          {details.type === MembershipEntriesControl.MONTHLY && lowerCaseFirstLetter(t('perMonth'))}
        </span>
        <span>{t('nRemaining', { count: details.limit - details.consumed })}</span>
      </div>
    </div>
  );
};

export default MembershipStampsDetail;
