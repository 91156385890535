import { InputHTMLAttributes, forwardRef } from 'react';
import Text from '../text';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  subtitle?: string;
  error?: string;
  variant?: 'default' | 'secondary';
}

export const GCheckbox = forwardRef<HTMLInputElement, Props>(
  ({ title, subtitle, error, className, variant = 'default', ...rest }, ref) => {
    const animationClasses = 'transition-all duration-150 ease-in';
    return (
      <label
        className={twMerge(
          clsx('flex group cursor-pointer relative', {
            'gap-2': variant === 'default',
          }),
          className,
        )}
      >
        <input type="checkbox" className="-left-[9999px] absolute peer" ref={ref} {...rest} />
        <div
          className={twMerge(
            clsx(
              'w-5 h-5 min-w-5 border-gray-300 relative top-px flex group items-center justify-center rounded border peer-focus:shadow-inputFocus peer-disabled:opacity-50 peer-disabled:border-gray-300 peer-disabled:bg-transparent',
              {
                'group-active:border-primary-600 group-active:bg-primary peer-checked:bg-primary contrast:group-active:border-contrast-primary-600 contrast:group-active:bg-contrast-primary contrast:peer-checked:bg-contrast-primary':
                  variant === 'default',
                'text-primary-500 peer-checked:bg-primary-50 peer-checked:border-primary-500 contrast:peer-checked:bg-contrast-primary-50 contrast:peer-checked:border-contrast-primary-500':
                  variant === 'secondary',
              },
            ),
            animationClasses,
          )}
        >
          <svg
            width="10"
            height="8"
            viewBox="0 0 10 8"
            fill="none"
            className={twMerge('opacity-0 peer-checked:group-[]:opacity-100', animationClasses)}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 1L3.5 6.5L1 4"
              stroke={clsx({ white: variant === 'default', currentColor: variant === 'secondary' })}
              strokeWidth="1.6666"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div>
          {title && (
            <Text variant="small" className="text-gray-700 font-medium">
              {title}
            </Text>
          )}
          {subtitle && (
            <Text variant="small" className="text-gray">
              {subtitle}
            </Text>
          )}
        </div>
      </label>
    );
  },
);

GCheckbox.displayName = 'GCheckbox';
