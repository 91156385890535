import { getTranslatorStringForPlurals } from '@/common/utils';
import { GCard } from '@/design-system/v3/g-card';
import { GProgressBar } from '@/design-system/v3/g-progress-bar/GProgressBar';
import { useTranslation } from 'react-i18next';

interface Props {
  percentage: number;
  invitesLeft: number;
  totalInvites: number;
}

export const BringAFriendOverviewBasicCard = ({ percentage, invitesLeft, totalInvites }: Props) => {
  const { t } = useTranslation();

  return (
    <GCard variant="secondary" className="bg-white">
      <div className="divide-y">
        <div className="px-4 pt-5 pb-2">
          <GProgressBar percentage={percentage} />

          <div className="flex justify-between mt-1 font-medium text-xs">
            <span>
              {t('invitedBreakage', {
                numerator: totalInvites - invitesLeft,
                denominator: totalInvites,
              })}
            </span>

            <span className="text-primary-500 contrast:text-contrast-primary-500">
              {t(getTranslatorStringForPlurals('invitesLeft', invitesLeft !== 1), {
                count: invitesLeft,
              })}
            </span>
          </div>
        </div>
        {/* <div className="p-4">
          <Button gsize="large" onClick={() => navigate(BRING_A_FRIEND_PATH)}>
            {t('inviteAFriend')}
          </Button>
        </div> */}
      </div>
    </GCard>
  );
};
