import clsx from 'clsx';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import ArrowUp from '../../../common/components/icon/icons/v2/arrowup.svg?react';
import { filterHtmlContent } from '@/common/utils';

interface Props {
  name?: string;
  value?: string;
  onChange?: (event: any) => void;
  error?: boolean;
  placeholder?: string;
  initialValue?: string | undefined;
  onSubmit?: () => Promise<void>;
  className?: string;
}

export const Textarea = forwardRef<{ focus: () => void }, Props>(
  (
    { value, onSubmit, initialValue: initialValueRaw, error = false, placeholder, name, onChange, className, ...rest },
    ref,
  ) => {
    const inputRef = useRef<HTMLDivElement | null>(null);
    const [currentValue, setCurrentValue] = useState<string | undefined>(value);
    const [initialValue, setInitialValue] = useState<string | undefined>(value || initialValueRaw);

    const isDirty = useMemo(() => {
      return currentValue !== initialValue;
    }, [currentValue, initialValue]);

    const canSubmit = useMemo(() => isDirty && !error, [isDirty, error]);

    const inputClasses = clsx(
      className,
      'p-4 pr-14 w-full rounded-md shadow-input text-sm text-gray-500 text-base h-max border border-gray-100 transition whitespace-pre-wrap',
      'focus:outline-none focus:ring-0 focus:ring-offset-0 focus:text-black-100',
      {
        'border-error-300 focus:border-error-300 focus:shadow-inputFocusError': error,
        'border-gray-300 focus:shadow-inputFocus focus:border-primary-300 contrast:focus:border-contrast-primary-300':
          !error,
      },
    );

    useEffect(() => {
      setInitialValue(initialValueRaw);
    }, [initialValueRaw]);

    const submitClasses = clsx(
      'absolute flex right-3 top-[11px] items-center justify-center outline-none border-0 w-[32px] h-[32px] rounded-full text-white',
      {
        'bg-purple-500': canSubmit,
        'bg-gray-400': !canSubmit,
      },
    );

    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current?.focus();
      },
    }));

    useEffect(() => {
      const el = inputRef.current;

      if (!el) {
        return;
      }

      function focus(e: FocusEvent) {
        const value = (e.target as HTMLElement).innerHTML;

        if (value === placeholder) {
          (e.target as HTMLElement).innerHTML = '';
        }
      }

      function blur(e: FocusEvent) {
        const value = (e.target as HTMLElement).innerHTML;

        if (value === '' && placeholder) {
          (e.target as HTMLElement).innerHTML = placeholder;
        }
      }

      el.addEventListener('focus', focus);
      el.addEventListener('blur', blur);

      return () => {
        el.removeEventListener('focus', focus);
        el.removeEventListener('blur', blur);
      };
    }, [ref, placeholder]);

    const handleInput = () => {
      const value = inputRef.current?.innerHTML;
      setCurrentValue(value);

      if (onChange) {
        onChange({ target: { name, value } });
      }
    };

    const handleSubmit = () => {
      if (onSubmit) {
        onSubmit();
      }

      const el = inputRef.current;

      if (el) {
        el.innerHTML = placeholder || '';
        el.blur();
      }
    };

    return (
      <div className="relative">
        {onSubmit && (
          <button className={submitClasses} onClick={handleSubmit} disabled={!canSubmit}>
            <ArrowUp />
          </button>
        )}
        <div
          ref={inputRef}
          className={inputClasses}
          placeholder={placeholder}
          contentEditable={true}
          onInput={handleInput}
          suppressContentEditableWarning={true}
          dangerouslySetInnerHTML={{
            __html: filterHtmlContent(initialValue || placeholder || ''),
          }}
          {...rest}
        />
      </div>
    );
  },
);

Textarea.displayName = 'Textarea';
