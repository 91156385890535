import { PageWrapper, PageHeader } from '@/common/components/page-wrapper';
import { PROFILE_PATH } from '@/web/routes';
import { BackToButton } from '@/web/views/extra-menu';
import { useTranslation } from 'react-i18next';
import MembershipList from '../components/MembershipList/MembershipList';
import MembershipListSkeleton from '../components/MembershipList/SkeletonLoaders/MembershipListSkeleton';

const MembershipsOverview = () => {
  const { t } = useTranslation();
  const isLoading = false;
  const content = (
    <section>
      <div className="p-4 flex items-center justify-center">
        <h2 className="text-typo-secondary">{t('yourMemberships')}</h2>
      </div>
      {isLoading ? <MembershipListSkeleton /> : <MembershipList />}
    </section>
  );

  return (
    <PageWrapper
      header={<PageHeader title={t('memberships')} leftAction={<BackToButton path={PROFILE_PATH} />} />}
      contentClasses="p-0"
      content={content}
    />
  );
};

export default MembershipsOverview;
