import MembershipInfoPanelItem from '../components/MembershipInfoPanelItem/MembershipInfoPanelItem';
import { formatWithT } from '@/i18n/utils';
import { useTranslation } from 'react-i18next';
import { BusinessUserMembershipDto } from '@/features/memberships/types/BusinessUserMembershipDto';
import { formatAmount } from '@/common/utils';
import { useBusiness } from '@/web/hooks/use-business';
import { useAuth } from '@/auth/hooks/use-auth';

type Props = {
  membership: BusinessUserMembershipDto;
};

const MembershipInfoPanel = ({ membership }: Props) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { businessUuid } = useBusiness();

  const paymentCollectionStrategy = user?.businesses?.find((business) => business.businessId === businessUuid)
    ?.paymentCollectionStrategy;

  return (
    <div>
      <div className="border border-borders-primary rounded-lg divide-y divide-borders-primary">
        <MembershipInfoPanelItem
          leftElement={
            <div>
              <h4 className="text-typo-primary font-semibold">{membership.name}</h4>
              <span>
                {t('everyWeek_other', {
                  count: 4,
                })}
              </span>
            </div>
          }
          rightElement={
            <>
              {membership.fullAmount === membership.amount && <span>{formatAmount(membership.fullAmount, 'EUR')}</span>}
              {membership.fullAmount !== membership.amount && (
                <div className="flex flex-col">
                  <span>{formatAmount(membership.amount, 'EUR')}</span>
                  <span className="line-through text-gray">{formatAmount(membership.fullAmount, 'EUR')}</span>{' '}
                </div>
              )}
            </>
          }
        />
        <MembershipInfoPanelItem
          leftElement={<span>{t('startDate')}</span>}
          rightElement={
            <span>
              {membership.activeAt === null && (
                <>{membership.activationStrategy === 'FIRST_USE' ? t('activeOnFirstUse') : '-'}</>
              )}
              {membership.activeAt && formatWithT(membership.activeAt, 'MMMM dd, yyyy')}
            </span>
          }
        />
        <MembershipInfoPanelItem
          leftElement={<span>{t('endDate')}</span>}
          rightElement={<span>{membership.expiresAt ? formatWithT(membership.expiresAt, 'MMMM dd, yyyy') : '-'}</span>}
        />
        {membership.firstUsedAt && (
          <MembershipInfoPanelItem
            leftElement={<span>{t('firstUsedOn')}</span>}
            rightElement={<span>{formatWithT(membership.firstUsedAt, 'MMMM dd, yyyy')}</span>}
          />
        )}
        {membership.discount && (
          <MembershipInfoPanelItem
            leftElement={<span>{t('discount')}</span>}
            rightElement={
              <div className="flex flex-col text-right">
                <span>- {formatAmount(membership.fullAmount - membership.amount)}</span>
                {membership.discount.expiresAt && (
                  <div className="flex flex-row text-gray text-xs space-x-1">
                    <span>{t('endDate')}:</span>
                    <span>{formatWithT(membership.discount.expiresAt, 'MMMM dd, yyyy')}</span>
                  </div>
                )}
              </div>
            }
          />
        )}
        {membership.nextPaymentAt && paymentCollectionStrategy && (
          <MembershipInfoPanelItem
            leftElement={<span>{t('payment')}</span>}
            rightElement={<span>{paymentCollectionStrategy === 'MANUAL' ? t('manual') : t('automatic')}</span>}
          />
        )}
      </div>
    </div>
  );
};

export default MembershipInfoPanel;
