import React, { createContext, useContext, useEffect, useCallback, useState } from 'react';

const AccessibilityContext = createContext<AccessibilitySettings>({
  fontScale: 1,
});

type AccessibilityProviderProps = {
  children: React.ReactNode;
};

export const useAccessibility = () => useContext(AccessibilityContext);

export const AccessibilityProvider = ({ children }: AccessibilityProviderProps) => {
  const [fontScale, setFontScale] = useState(window.fontScale ?? 1);

  const setFontSize = useCallback(() => {
    const newFontScale = window.fontScale ?? 1;
    const baseFontSize = 14; // in px
    const maxFontSize = 24; // in px
    setFontScale(newFontScale);

    const fontSizeInRem = Math.min(baseFontSize * newFontScale, maxFontSize) / 16;
    document.documentElement.style.setProperty('--font-size-base', `${fontSizeInRem}rem`);
  }, []);

  const setHighContrast = useCallback(() => {
    const contrastMediaQuery = window.matchMedia('(prefers-contrast: more)');
    if (contrastMediaQuery.matches || window.isHighContrastTextEnabled) {
      document.documentElement.classList.add('high-contrast');
    } else {
      document.documentElement.classList.remove('high-contrast');
    }
  }, []);

  useEffect(() => {
    setFontSize();
    setHighContrast();

    window.addEventListener('fontScaleChanged', setFontSize);

    const contrastMediaQuery = window.matchMedia('(prefers-contrast: more)');
    contrastMediaQuery.addEventListener('change', setHighContrast);

    return () => {
      window.removeEventListener('fontScaleChanged', setFontSize);
      contrastMediaQuery.removeEventListener('change', setHighContrast);
    };
  }, [setFontSize, setHighContrast]);

  return <AccessibilityContext.Provider value={{ fontScale }}>{children}</AccessibilityContext.Provider>;
};
