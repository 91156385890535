import { GLoader } from '@/design-system/g-loader';
import GymlySymbol from '@/gymly-symbol.svg?react';
import { useEmbed } from '@/common/hooks/use-embed';
import { isCustomApp } from '@/web/utils';

export const GPageLoader = ({ withLogo = false }: { withLogo?: boolean }) => {
  const embed = useEmbed();

  return (
    <div className="bg-white h-screen w-screen flex flex-col items-center justify-center">
      {!embed && withLogo && !isCustomApp() ? (
        <GymlySymbol className="w-16 h-16 text-[#6F04F4]" />
      ) : (
        <GLoader variant="secondary" />
      )}
    </div>
  );
};
