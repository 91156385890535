import { ArrowLeft, ArrowRight } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

type Props = {
  onPreviousPage: () => void;
  onNextPage: () => void;
  page: number;
  totalPages: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  isFetching: boolean;
};

export const Paginator = ({
  onPreviousPage,
  onNextPage,
  page,
  totalPages,
  canPreviousPage,
  canNextPage,
  isFetching = false,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-between py-4">
      <button
        className="flex gap-1 items-center border disabled:text-typo-secondary contrast:disabled:text-contrast-typo-secondary rounded-lg py-2 px-3"
        onClick={onPreviousPage}
        disabled={!canPreviousPage || isFetching}
      >
        <ArrowLeft />
        <span>{t('previous')}</span>
      </button>
      {!isFetching && (
        <span className="flex items-center gap-1">
          <div>{t('paginator.page')} </div>
          <strong>
            {page} / {totalPages}
          </strong>
        </span>
      )}
      <button
        className="flex gap-1 items-center border disabled:text-typo-secondary  contrast:disabled:text-contrast:typo-secondary rounded-lg py-2 px-3"
        onClick={onNextPage}
        disabled={!canNextPage || isFetching}
      >
        <span>{t('next')}</span>
        <ArrowRight />
      </button>
    </div>
  );
};
