import { motion, useReducedMotion } from 'framer-motion';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

const variants = {
  enter: () => {
    return {
      x: -20,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: () => {
    return {
      zIndex: 0,
      x: 20,
      opacity: 0,
    };
  },
};
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

export const DraggableArea = ({
  onDragLeft,
  onDragRight,
  children,
  className,
}: {
  onDragLeft: () => void;
  onDragRight: () => void;
  className?: string;
  children: ReactNode;
}) => {
  const shouldReduceMotion = useReducedMotion();

  return (
    <motion.div
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      drag="x"
      className={twMerge('min-h-contentHeight', className)}
      transition={{
        x: !shouldReduceMotion && { type: 'spring', stiffness: 300, damping: 30 },
        opacity: { duration: 0.2 },
      }}
      dragPropagation
      dragDirectionLock
      dragTransition={
        shouldReduceMotion ? { bounceStiffness: 1000, bounceDamping: 50 } : { bounceStiffness: 600, bounceDamping: 20 }
      }
      dragElastic={0.5}
      dragConstraints={{ bottom: 0, top: 0 }}
      dragSnapToOrigin
      onDragEnd={(e, { offset, velocity }) => {
        const swipe = swipePower(offset.x, velocity.x);

        if (swipe < -swipeConfidenceThreshold) {
          onDragRight();
        } else if (swipe > swipeConfidenceThreshold) {
          onDragLeft();
        }
      }}
    >
      {children}
    </motion.div>
  );
};
