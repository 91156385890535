import {
  Course,
  CourseDetail,
  CourseDetailWithHelpers,
  CourseGroup,
  CourseState,
  CourseType,
  CourseWithHelpers,
  CourseWithOverflow,
  Membership,
} from '@/web/types';
import { parseCourseISO } from '@/common/utils';
import {
  add,
  differenceInMinutes,
  endOfDay,
  format,
  getISOWeeksInYear,
  getISOWeek,
  isAfter,
  isBefore,
  isEqual,
  isPast,
  isSameDay,
  isToday,
  startOfDay,
} from 'date-fns';

export const LEFT_PADDING = 42;
export const TOTAL_HOURS = 24;
export const FIVE_MINUTES_IN_MS = 300000;
export const MAX_WEEKS_IN_YEAR = getISOWeeksInYear(new Date());
export const LEFT_STYLE = { paddingLeft: LEFT_PADDING };

export const calendarHeaderHeight = 38;
export const calendarHourUnit = 80;

const HOUR_UNIT = 60;
export const HOURS_ROWS = Array.from(Array(TOTAL_HOURS), (_, index) => {
  return (index < 10 ? `0${index}` : index) + ':00';
});

export const getCurrentWeek = () => getISOWeek(new Date());

export const getLocationFromUuid = (uuid: string, locations: any) => {
  return locations?.find((location: any) => location.id === uuid);
};

export const getCourseTypeFromUuid = (uuid: string, courseTypes: any) => {
  return courseTypes?.find((courseType: any) => courseType.id === uuid);
};

export const getMembershipFromUuid = (uuid: string, memberships: any) => {
  if (!uuid) {
    return null;
  }

  return memberships?.find((membership: Membership) => membership.id === uuid) as Membership | undefined;
};

const getCalendarDay = (date: Date) => {
  return {
    title: format(date, 'EEE'),
    date,
    subtitle: format(date, 'd'),
    isToday: isToday(date),
  };
};

export const getTopFromDate = (date: Date) => {
  const hour = Number(format(date, 'H'));
  const minutes = date.getMinutes();
  const minuteUnit = (calendarHourUnit / 60) * minutes;

  return hour * calendarHourUnit + minuteUnit;
};

export const getLeftFromDate = (date: Date, courseWidth: number) => {
  const formattedDate = format(date, 'i');
  const day = Number(formattedDate) - 1;

  return courseWidth * day;
};

export const getCourseHeight = (course: any) => {
  const startAtDate = typeof course.startAt === 'string' ? parseCourseISO(course.startAt, course) : course.startAt;
  const endAtDate = typeof course.endAt === 'string' ? parseCourseISO(course.endAt, course) : course.endAt;
  const diffMinutes = differenceInMinutes(endAtDate, startAtDate);
  const unit = calendarHourUnit / HOUR_UNIT;
  return unit * diffMinutes;
};

export const getCalendarDays = (date: Date, view: string) => {
  let counter = 1;
  const exit = view === 'DAY' ? 0 : 6;
  const result = [getCalendarDay(date)];

  while (counter <= exit) {
    result.push(getCalendarDay(add(date, { days: counter })));
    counter++;
  }

  return result;
};

export const getCourseTemplate = (course: any) => {
  const startAtDate = course.startAtDate;
  const endAtDate = course.endAtDate;
  const diffMinutes = differenceInMinutes(endAtDate, startAtDate);

  return diffMinutes > 45 ? 'normal' : 'small';
};

export const getCourseStyle = (event: any, width: number, view: string) => {
  const startDate = typeof event.startAt === 'string' ? parseCourseISO(event.startAt, event) : event.startAt;
  const left = view === 'DAY' ? 0 : getLeftFromDate(startDate, width);
  const top = getTopFromDate(startDate);
  const height = getCourseHeight(event);

  return {
    width,
    height,
    left,
    top,
    transform: `translate3d(${left}px, ${top}px, 0)`,
    cursor: 'pointer',
  };
};

export const sortCourses = (courses: CourseWithHelpers[]) => {
  return courses.sort((a, b) => parseCourseISO(a.startAt, a).getTime() - parseCourseISO(b.startAt, b).getTime());
};

export const getUniqueCourseId = (course: Course | CourseDetail) => {
  const isRecurring = !!course.recurringPattern;

  return isRecurring ? `${course.id}${course.startAt}` : course.id;
};

export const addHelpersToCourse = (
  course: Course | CourseDetail,
  activities: Record<string, CourseType>,
): CourseWithHelpers | CourseDetailWithHelpers => {
  const startAtDate = parseCourseISO(course.startAt, course);
  const endAtDate = parseCourseISO(course.endAt, course);
  const detailedActivities = course.activities?.map((activity) => activities[activity]) || [];

  return {
    ...course,
    detailedActivities,
    uniqueUuid: getUniqueCourseId(course),
    originalStartAt: course.startAt,
    originalStartAtDate: startAtDate,
    originalEndAt: course.endAt,
    originalEndAtDate: endAtDate,
    isPast: isPast(add(startAtDate, { minutes: detailedActivities[0].options.subscribeCourseMinutes || 0 })),
    startAtDate,
    endAtDate,
  };
};

export const coursesToCourseState = (courses: CourseWithHelpers[]): CourseState => {
  return courses.reduce((acc: CourseState, curr: CourseWithHelpers) => {
    acc[curr.uniqueUuid] = curr;

    return acc;
  }, {});
};

export const addHelpersToCourses = (courses: Course[], activities: Record<string, CourseType>) =>
  courses.map((course) => addHelpersToCourse(course, activities)) as CourseWithHelpers[];

export const getCourseOverflow = (course: CourseWithHelpers): CourseWithOverflow => {
  const startAtDate = course.startAtDate;
  const endAtDate = course.endAtDate;
  const isSameDayCheck = isSameDay(startAtDate, endAtDate);
  const result: CourseWithOverflow = {
    original: course,
    overflow: null,
  };

  if (!isSameDayCheck) {
    const startOfDayDate = startOfDay(endAtDate);
    const endOfDayDate = endOfDay(startAtDate);
    const difference = differenceInMinutes(endAtDate, endOfDayDate);

    if (difference) {
      const newCourse = {
        ...course,
        startAt: startOfDayDate.toISOString(),
        startAtDate: startOfDayDate,
      };

      course.endAt = endOfDayDate.toISOString();
      course.endAtDate = endOfDayDate;

      result.overflow = newCourse;
    }
  }
  return result;
};

export const removeOutsideStartAndEnd = ({
  data,
  start,
  end,
}: {
  data: CourseWithHelpers[];
  start: Date;
  end: Date;
}) => {
  return data.reduce((acc: CourseWithHelpers[], curr: CourseWithHelpers) => {
    if (
      isEqual(curr.startAtDate, start) ||
      isEqual(curr.endAtDate, end) ||
      (isAfter(curr.startAtDate, start) && isBefore(curr.endAtDate, end))
    ) {
      acc.push(curr);
    }

    return acc;
  }, []);
};

export const mapCourses = ({ data, start, end }: { data: CourseWithHelpers[]; start: Date; end: Date }) => {
  const withHelpers = data;
  const withOverlflows = withHelpers.reduce((acc: CourseWithHelpers[], curr: CourseWithHelpers) => {
    const result = getCourseOverflow(curr);

    acc.push(result.original);

    if (result.overflow) {
      acc.push(result.overflow);
    }

    return acc;
  }, []);

  const withinStartEnd = removeOutsideStartAndEnd({
    data: withOverlflows,
    start,
    end,
  });

  const withOrder = sortCourses(withinStartEnd);

  const withGroups = withOrder.reduce(
    (acc: Array<CourseWithHelpers | CourseGroup>, course: CourseWithHelpers, index: number) => {
      const lastEl = acc[acc.length - 1];
      const startDate = course.startAtDate;
      const endDate = course.endAtDate;
      const next = withOrder[index + 1];
      const nextStartDate = next ? next.startAtDate : 0;
      const prev = withOrder[index - 1];
      const prevEndDate = prev ? prev.endAtDate : 0;
      const prevStartDate = prev ? prev.startAtDate : 0;
      const sameDayWidthPrev = isSameDay(prevStartDate, startDate);
      const intersectWithPrev = !!(prevEndDate && startDate.getTime() + FIVE_MINUTES_IN_MS < prevEndDate.getTime());
      const intersectWithNext = !!(nextStartDate && endDate.getTime() > nextStartDate.getTime() + FIVE_MINUTES_IN_MS);

      if (intersectWithNext || intersectWithPrev) {
        if ((lastEl as CourseGroup)?.courses && sameDayWidthPrev && intersectWithPrev) {
          (lastEl as CourseGroup).courses.push(course);
        } else {
          acc.push({
            courses: [course],
            id: `group-${Math.random()}`,
          });
        }
      } else {
        acc.push({
          ...course,
        });
      }

      return acc;
    },
    [],
  );

  return withGroups;
};

export const skeletonAnimationClasses = 'bg-gray-200 animate-pulse rounded-full';

export const isCustomApp = () => {
  return window.AppBusinessUuid && window.AppBusinessUuid !== 'GYMLY';
};
