import { useQuery } from 'react-query';
import { getUserAvailableMemberships } from '@/business/endpoints';
import { FETCH_USER_AVAILABLE_MEMBERSHIPS } from '@/web/views/shop/memberships-tab';
import { useMemo } from 'react';
import { useBusiness } from '@/web/hooks/use-business';
import { Membership } from '@/web/types';

export const useUserAvailableMemberships = () => {
  const { businessUuid } = useBusiness();

  const { data } = useQuery(
    [FETCH_USER_AVAILABLE_MEMBERSHIPS, businessUuid],
    async () => {
      if (businessUuid) {
        return (await getUserAvailableMemberships(businessUuid)).filter((membership) => {
          // Hiding free membership until a flow for this exists.
          return membership.amount !== 0;
        });
      }

      return [];
    },
    {
      staleTime: 21600, // 6 hours
    },
  );

  return useMemo(
    () => ({
      memberships: data ?? [],
      membershipsById: (data ?? []).reduce(
        (acc, membership) => {
          acc[membership.id] = membership;
          return acc;
        },
        {} as Record<string, Membership>,
      ),
    }),
    [data],
  );
};
