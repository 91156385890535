import { useRecoilState } from 'recoil';
import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import { useTranslation } from 'react-i18next';
import 'flag-icons/css/flag-icons.min.css';
import Button from '@/design-system/v3/button';
import Text from '@/design-system/v3/text';
import { selectedLocaleState } from '@/i18n/atoms';
import { flagsMap, localeToLanguage, supportedLocales } from '@/i18n/utils';
import { PROFILE_PATH } from '@/web/routes';
import { BackToButton } from '../../extra-menu';
import { useAuth } from '@/auth/hooks/use-auth';
import { useCallback } from 'react';
import { useSnack } from '@/common/hooks/use-snack';
import { handleValidationError } from '@/common/utils';
import { updateUserLanguage } from '@/web/endpoints';

export const LanguagesView = () => {
  const { t } = useTranslation();
  const { token } = useAuth();
  const { errorMessage } = useSnack();
  const [selectedLocale, setSelectedLocale] = useRecoilState(selectedLocaleState);

  const handleChangeLocale = useCallback(
    (locale: string) => async () => {
      setSelectedLocale(locale);

      try {
        await updateUserLanguage(localeToLanguage(locale));
      } catch (e) {
        handleValidationError(e, undefined, errorMessage);
      }
    },
    [setSelectedLocale, errorMessage],
  );

  const content = (
    <div>
      <Text className="p-4 text-typo-secondary contrast:text-contrast-typo-secondary" variant="small">
        {t('languagesView.subtitle')}
      </Text>
      {supportedLocales.map((locale) => (
        <div
          key={locale.locale}
          className="border-t only:border-b last:border-b dark:last:dark:border-b-gray-700 dark:only:dark:border-b-gray-700 dark:border-t-gray-700 w-full flex items-center justify-between p-4"
        >
          <div className="flex items-center space-x-4">
            <span className={`fi fi-${flagsMap[locale.locale]}`}></span>

            <div>{locale.name}</div>
          </div>
          <Button
            variant="secondary"
            className="w-auto"
            disabled={selectedLocale === locale.locale}
            onClick={handleChangeLocale(locale.locale)}
          >
            {selectedLocale === locale.locale ? t('businessSwitch.btn.current') : t('businessSwitch.btn.select')}
          </Button>
        </div>
      ))}
    </div>
  );

  return (
    <PageWrapper
      header={
        <PageHeader
          title={t('profileMenuView.languages.title')}
          leftAction={<BackToButton path={token ? PROFILE_PATH : '/'} />}
        />
      }
      footerClasses="md:hidden"
      disableFooter={!token}
      contentClasses="p-0"
      content={content}
    />
  );
};
