import clsx from 'clsx';
import { GLoader } from '@/design-system/g-loader';
import { twMerge } from 'tailwind-merge';
import { ReactNode, useState } from 'react';
import { GPanel } from '../g-panel';
import Text from '../text';
import { useTranslation } from 'react-i18next';
import { HapticFeedbackType, triggerHapticFeedback } from '@/common/native-bridge/utils';

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'quaternary';
type Size = 'large' | 'regular' | 'small';
export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  gsize?: Size;
  loading?: boolean;
  icon?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  withConfirmation?: {
    title: ReactNode;
    content: ReactNode;
  };
  fullWidth?: boolean;
}

const Button = ({
  variant = 'primary',
  gsize = 'regular',
  loading = false,
  withConfirmation,
  children,
  className,
  startIcon,
  endIcon,
  type,
  fullWidth = true,
  icon = false,
  ...props
}: ButtonProps) => {
  const { t } = useTranslation();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const onClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    if (type === 'submit') {
      triggerHapticFeedback(HapticFeedbackType.IMPACT_LIGHT);
    }

    if (withConfirmation) {
      setShowConfirmation(true);
    } else {
      if (props.onClick) props.onClick(ev);
    }
  };

  const btnClasses = twMerge(
    buttonClasses({
      loading,
      variant,
      gsize,
      disabled: props.disabled,
      icon,
      fullWidth,
    }),
    className,
  );

  return (
    <>
      <button type={type} className={btnClasses} data-testid="button" {...props} onClick={onClick}>
        {loading ? (
          <GLoader variant={props.disabled || variant === 'quaternary' ? 'primary' : variant} />
        ) : (
          <>
            {startIcon && <div className="mr-2">{startIcon}</div>}
            <div>{children}</div>
            {endIcon && <div className="ml-2">{endIcon}</div>}
          </>
        )}
      </button>
      {withConfirmation && (
        <GPanel
          title={withConfirmation.title}
          open={showConfirmation}
          className="h-[50vh]"
          actions={
            <ConfirmationButton loading={loading} onClick={props.onClick}>
              {t('confirm')}
            </ConfirmationButton>
          }
          onClose={() => setShowConfirmation(false)}
        >
          <Text className="mb-4">{withConfirmation.content}</Text>
        </GPanel>
      )}
    </>
  );
};

export const ConfirmationButton = (props: ButtonProps) => {
  return <Button type="submit" {...props} />;
};

export const buttonClasses = ({
  loading,
  disabled,
  icon,
  variant = 'primary',
  gsize = 'regular',
  fullWidth = true,
}: {
  loading?: boolean;
  disabled?: boolean | undefined;
  gsize?: Size;
  variant?: ButtonVariant;
  icon?: boolean;
  fullWidth?: boolean;
}) =>
  clsx(
    'inline-flex items-center text-base px-4 rounded-lg shadow-btnShadow font-neutral flex items-center justify-center transition',
    {
      'h-12': gsize === 'large',
      'h-10': gsize === 'regular',
      'h-8': gsize === 'small',
      'w-10': icon && gsize === 'regular',
      'w-8': icon && gsize === 'small',
      'w-full': fullWidth,
    },
    {
      'pointer-events-none': loading,
      [primaryClasses]: variant === 'primary' && !disabled,
      [secondaryClasses]: variant === 'secondary' && !disabled,
      [tertiaryClasses]: variant === 'tertiary' && !disabled,
      [quaternaryClasses]: variant === 'quaternary' && !disabled,
      [disabledClasses]: disabled,
      'rounded-full': icon,
    },
  );

const primaryClasses = `
  bg-primary
  text-primary-contrast-text
  active:bg-primary-600 
  focus:shadow-[0_0_0_2px_white,_0_0_0_4px_theme(colors.primary.500)]

  contrast:bg-contrast-primary
  contrast:active:bg-contrast-primary-600
  contrast:text-contrast-primary-contrast-text
`;

const secondaryClasses = `
  bg-white
  border 
  border-borders-primary
  text-gray-700

  contrast:border-contrast-borders-primary
  active:bg-gray-50 
  active:text-gray-800
  `;

const tertiaryClasses = `
  bg-monochrome-white
  active:bg-gray-300 
  text-primary-700
  hover:text-primary-900

  contrast:text-contrast-primary-700
  contrast:hover:text-contrast-primary-900
`;

const quaternaryClasses = `
  text-white
  bg-black-100
  active:bg-gray-800 
  active:text-gray-50
`;

const disabledClasses = `
  !bg-gray-200 
  !border 
  !border-gray-100
  !active:bg-gray-200
  !text-typo-secondary
  dark:!bg-gray-500
  dark:!text-gray-600
  dark:!border-gray-600

  contrast:!text-contrast-typo-secondary
`;

export default Button;
