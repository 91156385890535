import { useQuery } from 'react-query';
import { useBusiness } from '@/web/hooks/use-business';
import {
  FETCH_BUSINESS_USER_MEMBERSHIPS,
  getBusinessUserMemberships,
} from '@/features/memberships/api/BusinessUserMembershipApi';

export const useBusinessUserMemberships = () => {
  const { businessUuid } = useBusiness();

  const { data } = useQuery([FETCH_BUSINESS_USER_MEMBERSHIPS, businessUuid], async () => {
    if (businessUuid) {
      return getBusinessUserMemberships(businessUuid);
    }
  });

  return data ?? [];
};
