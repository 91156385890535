import clsx from 'clsx';
import { CircleNotch } from 'phosphor-react';
import { twMerge } from 'tailwind-merge';
import { useSearchParams } from 'react-router-dom';
import { useEmbed } from '@/common/hooks/use-embed';

type Variant = 'primary' | 'secondary' | 'tertiary';

export const GLoader = ({ variant = 'primary', className }: { variant?: Variant; className?: string }) => {
  const embed = useEmbed();
  const [searchParams] = useSearchParams();
  const classes = clsx('w-6 h-6', {
    'text-white': variant === 'primary',
    'text-primary contrast:text-contrast-primary': variant === 'secondary',
  });

  const brandPrimaryColor = searchParams.get('brandPrimaryColor');

  return (
    <div className="animate-spin duration-300">
      <CircleNotch
        className={twMerge(classes, className)}
        style={{
          color: embed && brandPrimaryColor ? brandPrimaryColor : null,
        }}
      />
    </div>
  );
};
