import React from 'react';

type Props = {
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
};

const MembershipInfoPanelItem = ({ leftElement, rightElement }: Props) => {
  return (
    <div className="flex justify-between p-4 items-center">
      <div className="text-typo-secondary">{leftElement}</div>
      <div>{rightElement}</div>
    </div>
  );
};

export default MembershipInfoPanelItem;
