import { RequireAuth } from '@/auth/components/require-auth';
import { Home } from '@/common/views/home';

export const CALENDAR_PATH = '/calendar';

export const CALENDAR_ROUTES = [
  {
    path: `${CALENDAR_PATH}/:id`,
    element: (
      <RequireAuth>
        <Home />
      </RequireAuth>
    ),
  },
];
