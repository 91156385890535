import { useAuth } from '@/auth/hooks/use-auth';
import { OnboardingUserInformation } from './onboarding-user-information';
import { OnboardingReviewForm } from './onboarding-review-form';

import { OnboadingLocation } from './onboarding-locations';
import { OnboardingMembership } from './onboarding-membership';
import { atom, useRecoilState } from 'recoil';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useBusiness } from '@/web/hooks/use-business';
import { Control, FormState, useForm, UseFormHandleSubmit, UseFormSetError } from 'react-hook-form';
import { getFromStorage, removeFromStorage, setInStorage } from '@/common/utils';
import {
  ONBOARDING_BUSINESS_ID,
  ONBOARDING_BUSINESS_LOCATION_ID,
  ONBOARDING_BUSINESS_MEMBERSHIP_ID,
} from '@/common/constants';
import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import clsx from 'clsx';
import { UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form/dist/types/form';
import { getDefaultCallingCode } from '@/i18n/utils';
import { useUserDataSettings } from '@/web/hooks/use-user-data-settings';
import { Role, UserDataSetting } from '@/web/types';
import { getMembershipDetail } from '@/web/endpoints';
import { businessMembershipsState } from '@/business/atoms';
import { onLogout } from '@/auth/utils';
import { ProrateResult } from '@/auth/types';
import { BusinessLogoImage } from '@/business/components/business-logo-image/BusinessLogoImage';

export type Step = 'step1' | 'step2' | 'step3' | 'step4';

export const onboardingReviewState = atom({
  key: 'onboardingReviewState',
  default: false,
});

export interface OnboardingFormData {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phoneNumber: string;
  street: string;
  city: string;
  postalCode: string;
  country: string;
  membershipId: string;
  primaryLocationId: string;
  email: string;
  password: string;
  discountCode: string | null;
}

interface OnboardingFormHook {
  membershipId: string;
  primaryLocationId: string;
  register: UseFormRegister<OnboardingFormData>;
  control: Control<OnboardingFormData, undefined>;
  formState: FormState<OnboardingFormData>;
  handleSubmit: UseFormHandleSubmit<OnboardingFormData, undefined>;
  prorateResult: ProrateResult | null;
  setLocation: (id: string) => void;
  setMembership: (id: string) => void;
  setProrateResult: (result: ProrateResult | null) => void;
  setError: UseFormSetError<OnboardingFormData>;
  setValue: UseFormSetValue<OnboardingFormData>;
  watch: UseFormWatch<OnboardingFormData>;
}

const MEMBERSHIP_ID_PARAM = 'membershipId';
export const useOnboardingForm = (): OnboardingFormHook => {
  const { user } = useAuth();
  const [search, setSearch] = useSearchParams();
  const { businessUuid } = useBusiness();
  const [memberships, setMemberships] = useRecoilState(businessMembershipsState);
  const membershipFromSearch = search.get(MEMBERSHIP_ID_PARAM);
  const { isPropertyRequired, isUserDataLoaded } = useUserDataSettings();
  const [prorateResult, setProrateResult] = useState<ProrateResult | null>(null);
  const setValueSettings = useMemo(() => ({ shouldValidate: true, shouldDirty: true, shouldTouch: true }), []);
  const { register, handleSubmit, control, setValue, watch, formState, setError } = useForm<OnboardingFormData>({
    mode: 'onChange',
    defaultValues: {
      membershipId: getFromStorage(ONBOARDING_BUSINESS_MEMBERSHIP_ID) || '',
      primaryLocationId: getFromStorage(ONBOARDING_BUSINESS_LOCATION_ID) || '',
      email: user?.email || '',
      password: '',
      dateOfBirth: user?.dateOfBirth || '',
      phoneNumber: user?.phoneNumber || '',
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      address: user?.address?.street || '',
      city: user?.address?.city || '',
      postalCode: user?.address?.postalCode || '',
      country: user?.address?.country || '',
      discountCode: null,
    },
  });

  const phoneNumber = watch('phoneNumber');

  const setLocation = (locationId: string) => {
    if (locationId) {
      setInStorage(ONBOARDING_BUSINESS_LOCATION_ID, locationId);
    } else {
      removeFromStorage(ONBOARDING_BUSINESS_LOCATION_ID);
    }

    setValue('primaryLocationId', locationId, setValueSettings);
  };
  const setMembership = useCallback(
    (membershipId: string) => {
      if (membershipId) {
        setInStorage(ONBOARDING_BUSINESS_MEMBERSHIP_ID, membershipId);
      } else {
        removeFromStorage(ONBOARDING_BUSINESS_MEMBERSHIP_ID);
      }

      setValue('membershipId', membershipId, setValueSettings);
    },
    [setValue, setValueSettings],
  );

  const primaryLocationId = watch('primaryLocationId');
  const membershipId = watch('membershipId');

  useEffect(() => {
    if (!membershipFromSearch || !businessUuid) {
      return;
    }

    setSearch((params) => {
      params.delete(MEMBERSHIP_ID_PARAM);

      return params;
    });

    (async () => {
      const membership = await getMembershipDetail(businessUuid, membershipFromSearch);

      if (!memberships?.find((m) => m.id === membershipFromSearch)) {
        setMemberships((prev) => [
          ...(prev || []),
          {
            ...membership,
            value: membership.id,
            label: membership.name,
          },
        ]);
      }

      setMembership(membershipFromSearch);
    })();
  }, [memberships, setMemberships, setMembership, setSearch, membershipFromSearch, businessUuid]);

  useEffect(() => {
    if (
      !isUserDataLoaded ||
      !isPropertyRequired(UserDataSetting.PHONE_NUMBER_REQUIRED, Role.MEMBER) ||
      phoneNumber.length > 0
    ) {
      return;
    }

    const callingCode = getDefaultCallingCode();

    if (callingCode) {
      setValue('phoneNumber', callingCode);
    }
  }, [isPropertyRequired, isUserDataLoaded, phoneNumber, setValue]);

  return {
    register,
    membershipId,
    primaryLocationId,
    prorateResult,
    control,
    formState,
    handleSubmit,
    setLocation,
    setMembership,
    setProrateResult,
    setError,
    setValue,
    watch,
  };
};

export const OnboardingFormContext = createContext({} as OnboardingFormHook);
export const OnboardingFormProvider = ({ children }: { children: ReactNode }) => {
  const form = useOnboardingForm();
  return <OnboardingFormContext.Provider value={form}>{children}</OnboardingFormContext.Provider>;
};

export const OnboardingView = () => {
  const { membershipId, primaryLocationId, setLocation, setMembership } = useContext(OnboardingFormContext);
  const { id: businessUuidParam } = useParams();
  const { businessUuid, setBusinessUuid } = useBusiness();
  const [reviewAndPay, setReviewStep] = useRecoilState(onboardingReviewState);
  const [isNewUser, setIsNewUser] = useState(true);
  const { user } = useAuth();
  const navigate = useNavigate();

  const getOnboardingStep = (): Step => {
    if (!primaryLocationId) {
      return 'step1';
    }

    if (!membershipId) {
      return 'step2';
    }

    if (!reviewAndPay) {
      return 'step3';
    }

    return 'step4';
  };

  const step = getOnboardingStep();

  useEffect(() => {
    if (businessUuidParam) {
      if (businessUuidParam !== businessUuid) {
        setLocation('');
        setMembership('');
      }

      setInStorage(ONBOARDING_BUSINESS_ID, businessUuidParam);
      setBusinessUuid(businessUuidParam);
    } else if (getFromStorage(ONBOARDING_BUSINESS_ID)) {
      navigate(`/onboarding/${getFromStorage(ONBOARDING_BUSINESS_ID)}`);
    }
  }, [setBusinessUuid, businessUuidParam, navigate, user, businessUuid, setLocation, setMembership]);

  const onboardingTemplate = useMemo(() => {
    if (step === 'step1') {
      return <OnboadingLocation onNextStep={(locationId) => setLocation(locationId)} />;
    }

    if (step === 'step2')
      return (
        <OnboardingMembership
          onNextStep={(membershipId) => setMembership(membershipId)}
          onPreviousStep={() => setLocation('')}
        />
      );

    if (step === 'step3')
      return (
        <OnboardingUserInformation
          onPreviousStep={() => setMembership('')}
          onNextStep={(isNewUser) => {
            setReviewStep(true);
            setIsNewUser(isNewUser);
          }}
        />
      );

    if (step === 'step4')
      return (
        <OnboardingReviewForm
          isNewUser={isNewUser}
          onPreviousStep={() => {
            if (!isNewUser) {
              onLogout();
            }
            setReviewStep(false);
          }}
        />
      );
  }, [step, isNewUser, setLocation, setMembership, setReviewStep]);

  const classes = clsx('', {
    'md:px-0 md:max-w-none': step === 'step2' || step === 'step3' || step === 'step4',
  });

  return (
    <PageWrapper
      withLocaleSwitcher
      header={<PageHeader title={<BusinessLogoImage />} />}
      content={onboardingTemplate}
      contentClasses={classes}
      disableFooter
    />
  );
};
