import { useBusiness } from '@/web/hooks/use-business';
import React from 'react';
import logo from '@/logo.svg';

type Props = {
  children: React.ReactNode;
  locationId?: string | null;
  imagePath?: string | null;
};

const SplitScreenImageLayout = ({ children, locationId, imagePath }: Props) => {
  const { businessLocations, businessLogo } = useBusiness();
  const location = businessLocations?.find((loc) => loc.id === locationId);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 w-full md:h-screen md:overflow-hidden">
      <div className="px-4 lg:px-16 xl:px-32 md:overflow-y-auto pt-4">{children}</div>
      <div
        className="bg-center bg-no-repeat bg-gray-100 hidden md:block bg-cover"
        style={{ backgroundImage: `url(${imagePath ?? location?.publicImageUrl ?? businessLogo ?? logo})` }}
      />
    </div>
  );
};

export default SplitScreenImageLayout;
