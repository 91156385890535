import { GAvatar } from '@/design-system/g-avatar';
import { GLoader } from '@/design-system/g-loader';
import Button from '@/design-system/v3/button';
import { GCheckbox } from '@/design-system/v3/g-checkbox';
import { checkinAttendees, FETCH_COURSE_DETAIL_QUERY, FETCH_COURSE_MEMBERS_QUERY } from '@/web/endpoints';
import { useBusiness } from '@/web/hooks/use-business';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader, PageWrapper } from '../../page-wrapper';
import { BackToButton } from '@/web/views/extra-menu';
import { getCourseDetailPath } from '@/web/routes';
import { NoResultsFound } from '../../no-results-found';
import { useTranslation } from 'react-i18next';
import OneFitIcon from '@/common/components/icon/icons/v2/one-fit.svg?react';
import ClassPassIcon from '@/icons/classpass.svg?react';
import { useCourseMembers } from '@/web/views/course-detail';
import { CourseDetailMemberType, CourseMemberStatus } from '@/web/types';
import { Badge, BadgeVariant } from '@/design-system/v3/badge';
import clsx from 'clsx';
import { useSnack } from '@/common/hooks/use-snack';
import { handleValidationError } from '@/common/utils';

const allowedCheckinTypes = ['ADMIN', 'STAFF', 'TEACHER'];

export const CourseAttendeesCheckin = () => {
  const { t } = useTranslation();
  const { businessUser } = useBusiness();
  const { successMessage, errorMessage } = useSnack();
  const navigate = useNavigate();
  const canCheckinAttendees = allowedCheckinTypes.includes(businessUser?.type || '');
  const qc = useQueryClient();
  const { uuid, startAt } = useParams();
  const [attendeesList, setAttendeesList] = useState<Record<string, boolean>>({});
  const { members, isFetching } = useCourseMembers();

  const onChange = (memberId: string) => {
    if (!canCheckinAttendees) return;

    const copy = { ...attendeesList };

    copy[memberId] = !copy[memberId];

    setAttendeesList(copy);
  };

  const handleCheckAll = () => {
    if (!canCheckinAttendees) return;

    const copy = { ...attendeesList };

    if (Object.values(copy).every((e) => e)) {
      Object.keys(copy).forEach((key) => {
        copy[key] = false;
      });
    } else {
      Object.keys(copy).forEach((key) => {
        copy[key] = true;
      });
    }

    setAttendeesList(copy);
  };

  const hasAllChecked = useMemo(() => Object.values(attendeesList).every((e) => e), [attendeesList]);

  const { mutate, isLoading } = useMutation(
    (data: string[]) => {
      if (uuid && startAt && canCheckinAttendees) return checkinAttendees(uuid, startAt, data);
      return Promise.resolve(null);
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([FETCH_COURSE_DETAIL_QUERY, uuid]);
        qc.invalidateQueries([FETCH_COURSE_MEMBERS_QUERY, uuid]);
        navigate(getCourseDetailPath(uuid as string, startAt as string, 'attendees'));

        successMessage(t('attendeesView.snack.checkinSuccess.title'), {
          detailedMessage: t('attendeesView.snack.checkinSuccess.message'),
        });
      },
      onError: (e) => {
        handleValidationError(e, undefined, errorMessage);
      },
    },
  );

  useEffect(() => {
    setAttendeesList(
      (members ?? []).reduce(
        (acc, member) => {
          acc[member.id] = member.status === CourseMemberStatus.ATTENDED;

          return acc;
        },
        {} as Record<string, boolean>,
      ),
    );
  }, [members]);

  const checkedAttendees = useMemo(() => {
    return Object.entries(attendeesList)
      .filter((e) => !!e[1])
      .map((e) => e[0]);
  }, [attendeesList]);

  const content = isFetching ? (
    <div className="flex items-center justify-center h-contentHeight">
      <GLoader variant="secondary" />
    </div>
  ) : members.length === 0 ? (
    <div className="flex flex-col items-center justify-center h-contentHeight">
      <NoResultsFound label={t('attendeesView.noResults.title')} />
    </div>
  ) : (
    <div className="flex flex-col justify-between min-h-full">
      <div>
        <div className="flex justify-between items-center py-2 px-4 bg-gray-25 border-b border-gray-50">
          <div className="text-secondary">
            {checkedAttendees.length}/{members.length}
          </div>
          <div className="flex items-center space-x-4">
            <label htmlFor="attendee-check-all" className="text-gray-600">
              {t('selectAll')}
            </label>
            <GCheckbox id="attendee-check-all" onChange={handleCheckAll} checked={hasAllChecked} />
          </div>
        </div>
        {members
          .sort((a, b) => {
            if (attendeesList[a.id] && !attendeesList[b.id]) return 1;
            if (!attendeesList[a.id] && attendeesList[b.id]) return -1;

            if (a.fullName && b.fullName) {
              return a.fullName.localeCompare(b.fullName);
            }

            return 0;
          })
          .map((member) => (
            <button
              key={member.id}
              onClick={() => onChange(member.id)}
              className={clsx('w-full p-4 border-b border-b-borders-secondary active:bg-gray-50', {
                'bg-gray-25': attendeesList[member.id] || false,
                'bg-white': !attendeesList[member.id],
              })}
            >
              <div className="flex flex-row justify-between items-center w-full">
                <div className="flex flex-row items-center space-x-2">
                  <>
                    {canCheckinAttendees && member.externalProviderId === 'FITOGRAM' && (
                      <GAvatar
                        key={member.id}
                        size="2xs"
                        className="first:ml-0 -ml-px"
                        style={{
                          boxShadow: '0 0 0 2px white',
                        }}
                      >
                        <OneFitIcon className="absolute w-full h-full object-cover rounded-full" />
                      </GAvatar>
                    )}
                    {canCheckinAttendees && member.externalProviderId === 'CLASS_PASS' && (
                      <GAvatar
                        key={member.id}
                        size="2xs"
                        className="first:ml-0 -ml-px"
                        style={{
                          boxShadow: '0 0 0 2px white',
                        }}
                      >
                        <ClassPassIcon className="absolute w-full h-full object-cover rounded-full" />
                      </GAvatar>
                    )}
                    {(!canCheckinAttendees || !member.externalProviderId) && (
                      <GAvatar
                        key={member.id}
                        size="2xs"
                        className="first:ml-0 -ml-px"
                        style={{
                          boxShadow: '0 0 0 2px white',
                        }}
                        path={member?.profileImageUrl}
                      />
                    )}
                  </>
                  <div className="flex items-center justify-between w-full text-left mr-2">
                    <div
                      className={clsx({
                        'break-all': member.birthday,
                        'text-typo-secondary': attendeesList[member.id] || false,
                      })}
                    >
                      {member.fullName || member.firstName || t('anonymousMember')}
                      {member.type === CourseDetailMemberType.GUEST && ` (${t('guest')})`}
                    </div>
                    {member.birthday && (
                      <Badge variant={BadgeVariant.SUCCESS} className="mx-2 px-3">
                        <span className="inline-flex mr-2">🎉</span> {t('birthday')}
                      </Badge>
                    )}
                  </div>
                </div>
                {canCheckinAttendees && (
                  <GCheckbox
                    className="pointer-events-none select-none"
                    readOnly
                    checked={attendeesList[member.id] || false}
                  />
                )}
              </div>
            </button>
          ))}
      </div>
    </div>
  );

  return (
    <PageWrapper
      header={
        <PageHeader
          leftAction={uuid && startAt ? <BackToButton path={getCourseDetailPath(uuid, startAt, 'attendees')} /> : null}
          title={t('checkIn')}
        />
      }
      footerClasses="px-4"
      footer={
        <div>
          <Button
            className="mt-4"
            loading={isLoading}
            onClick={() => {
              mutate(
                Object.entries(attendeesList)
                  .filter((e) => !!e[1])
                  .map((e) => e[0]),
              );
            }}
          >
            {t('confirm')}
          </Button>
        </div>
      }
      contentClasses="p-0"
      content={<div className="bg-gray-25 h-full">{content}</div>}
      embedHeader
      embedFooter
    />
  );
};
