import { useAuth } from '@/auth/hooks/use-auth';
import { getEmailValidator, getNameValidator, getPasswordValidator, getRequiredValidator } from '@/common/validators';
import { Controller } from 'react-hook-form';
import { countries } from '../../../utils';
import { GGrid } from '@/design-system/v3/g-grid';
import { GInput } from '@/design-system/v3/g-input';
import { GSelect } from '@/design-system/v3/g-select';
import { OnboardingStepsLayout } from '../../../onboarding-steps-layout';
import { OnboardingFooter } from '../../../onboarding-footer';
import { OnboardingTransition } from '../../../onboarding-transition';
import { OnboardingFormContext, OnboardingFormData } from '../../..';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserDataSettings } from '@/web/hooks/use-user-data-settings';
import { Role, UserDataSetting } from '@/web/types';
import i18next from 'i18next';
import { GPhone } from '@/design-system/v3/g-phone';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { GDateOfBirth } from '@/design-system/v3/g-date-of-birth';
import Text from '@/design-system/v3/text';

interface Props {
  onSignIn: () => void;
  onNextStep: () => void;
  onPreviousStep?: () => void;
}

export const OnboardingProfileForm = ({ onNextStep, onPreviousStep, onSignIn }: Props) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
  } = useContext(OnboardingFormContext);
  const { isPropertyRequired, isUserDataLoaded } = useUserDataSettings();
  const { user } = useAuth();
  const isPartOfBusiness = !!user?.businesses?.length;

  const addressValidator = useCallback(
    (value: string, formValues: OnboardingFormData) => {
      const isEmpty = !value || value.length === 0;
      const hasAddress = Boolean(formValues.city || formValues.street || formValues.postalCode || formValues.country);

      if (isEmpty && hasAddress) {
        return i18next.t('requiredField');
      }

      if (isEmpty && isPropertyRequired(UserDataSetting.ADDRESS_REQUIRED, Role.MEMBER)) {
        return i18next.t('requiredField');
      }

      return true;
    },
    [isPropertyRequired],
  );

  const onSignInClicked = () => {
    setValue('password', '');
    setError('email', {});
    onSignIn();
  };

  return (
    <OnboardingStepsLayout
      subTitleComponent={
        <Text className="text-typo-secondary contrast:text-contrast-typo-secondary mt-10">
          {t('alreadyHaveAGymlyAccount')}{' '}
          <span
            className="text-typo-primary contrast:text-contrast-typo-primary underline cursor-pointer"
            onClick={onSignInClicked}
          >
            {t('signIn')}
          </span>
        </Text>
      }
      title={t('onboarding.personalInfo.title')}
    >
      <OnboardingTransition>
        {isUserDataLoaded && (
          <div className="flex flex-col space-y-4">
            <GInput
              {...control.register('email', {
                ...getEmailValidator(),
              })}
              readOnly={!!user?.email}
              error={
                errors.email?.message?.toString() === t('emailAlreadyRegistered') ? (
                  <>
                    {t('emailAlreadyRegistered')}{' '}
                    <span
                      className="text-typo-primary contrast:text-contrast-typo-primary underline cursor-pointer"
                      onClick={onSignInClicked}
                    >
                      {t('signInHere')}
                    </span>
                  </>
                ) : (
                  errors.email?.message?.toString()
                )
              }
              type="email"
              placeholder={t('authView.form.email.placeholder')}
              title={t('authView.form.email.title')}
              required
            />
            <div className={user?.email ? 'hidden' : undefined}>
              <GInput
                {...control.register('password', {
                  ...(user?.email ? {} : getPasswordValidator()),
                })}
                readOnly={!!user?.email}
                name="password"
                type="password"
                autoComplete="new-password"
                placeholder={t('authView.form.password.placeholder')}
                error={errors.password?.message?.toString()}
                title={t('authView.form.password.title')}
                required
              />
            </div>
            <GInput
              error={errors.firstName?.message?.toString()}
              title={t('authView.form.firstName.title')}
              {...control.register('firstName', {
                ...getNameValidator(),
              })}
              required
            />

            <GInput
              error={errors.lastName?.message?.toString()}
              title={t('authView.form.lastName.title')}
              required={isPropertyRequired(UserDataSetting.LAST_NAME_REQUIRED, Role.MEMBER)}
              {...control.register('lastName', {
                ...getNameValidator(isPropertyRequired(UserDataSetting.LAST_NAME_REQUIRED, Role.MEMBER)),
              })}
              optionalLabel
            />

            <Controller
              name={'dateOfBirth'}
              control={control}
              rules={{
                ...(isPropertyRequired(UserDataSetting.DATE_OF_BIRTH_REQUIRED, Role.MEMBER)
                  ? getRequiredValidator()
                  : {}),
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <GDateOfBirth
                    required={isPropertyRequired(UserDataSetting.DATE_OF_BIRTH_REQUIRED, Role.MEMBER)}
                    error={errors.dateOfBirth?.message}
                    onChange={onChange}
                    value={value}
                    optionalLabel
                  />
                );
              }}
            />

            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                validate: (value: string | undefined | null): string | boolean => {
                  if (!value || value.length === 0) return true;

                  return value && isValidPhoneNumber(value) ? true : i18next.t('invalidFormat');
                },
                ...(isPropertyRequired(UserDataSetting.PHONE_NUMBER_REQUIRED, Role.MEMBER)
                  ? getRequiredValidator()
                  : {}),
              }}
              render={({ field: { onChange, value } }) => (
                <GPhone
                  title={t('authView.form.phoneNumber.title')}
                  helperText={t('authView.form.phoneNumber.helperText')}
                  required={isPropertyRequired(UserDataSetting.PHONE_NUMBER_REQUIRED, Role.MEMBER)}
                  onChange={onChange}
                  value={value}
                  error={errors.phoneNumber?.message}
                  optionalLabel={!isPropertyRequired(UserDataSetting.PHONE_NUMBER_REQUIRED, Role.MEMBER)}
                />
              )}
            />

            <Controller
              name={'country'}
              control={control}
              rules={{
                validate: addressValidator,
                ...(isPropertyRequired(UserDataSetting.ADDRESS_REQUIRED, Role.MEMBER) ? getRequiredValidator() : {}),
              }}
              render={({ field: { onChange, value } }) => (
                <GSelect
                  onChange={onChange}
                  options={countries}
                  value={value}
                  title={t('addressForm.country.title')}
                  error={!!errors.country?.message}
                  required={isPropertyRequired(UserDataSetting.ADDRESS_REQUIRED, Role.MEMBER)}
                  optionalLabel
                />
              )}
            />

            <GInput
              error={!!errors.street?.message}
              title={t('addressForm.address.title')}
              {...control.register('street', {
                validate: addressValidator,
                ...getNameValidator(isPropertyRequired(UserDataSetting.ADDRESS_REQUIRED, Role.MEMBER)),
              })}
              required={isPropertyRequired(UserDataSetting.ADDRESS_REQUIRED, Role.MEMBER)}
              optionalLabel
            />

            <div>
              <GGrid container className="gap-x-2">
                <GGrid item xs={6} md={6}>
                  <GInput
                    error={!!errors.postalCode?.message}
                    title={t('addressForm.postalCode.title')}
                    {...control.register('postalCode', {
                      validate: addressValidator,
                      ...getNameValidator(isPropertyRequired(UserDataSetting.ADDRESS_REQUIRED, Role.MEMBER)),
                    })}
                    required={isPropertyRequired(UserDataSetting.ADDRESS_REQUIRED, Role.MEMBER)}
                  />
                </GGrid>
                <GGrid item xs={6} md={6}>
                  <GInput
                    error={!!errors.city?.message}
                    title={t('addressForm.city.title')}
                    {...control.register('city', {
                      validate: addressValidator,
                      ...getNameValidator(isPropertyRequired(UserDataSetting.ADDRESS_REQUIRED, Role.MEMBER)),
                    })}
                    required={isPropertyRequired(UserDataSetting.ADDRESS_REQUIRED, Role.MEMBER)}
                  />
                </GGrid>
              </GGrid>
            </div>
          </div>
        )}
        <OnboardingFooter
          onPreviousStep={onPreviousStep}
          onNextStep={handleSubmit(onNextStep)}
          nextTitle={isPartOfBusiness ? t('complete') : undefined}
        />
      </OnboardingTransition>
    </OnboardingStepsLayout>
  );
};
