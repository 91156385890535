import React, { useCallback } from 'react';
import MembershipStatus from '../MembershipStatus/MembershipStatus';
import { MembershipStatus as MembershipStatusEnum } from '../../enums/MembershipStatus.enum';
import Button from '@/design-system/v3/button';
import { twMerge } from 'tailwind-merge';
import { panelState } from '@/common/atoms';
import { useSetRecoilState } from 'recoil';
import MembershipInfoPanel from '../../panels/MembershipInfoPanel';
import { useAuth } from '@/auth/hooks/use-auth';
import MembershipStampsDetail from '@/common/components/memebership-stamps-detail/MembershipStampsDetail';
import { Trans, useTranslation } from 'react-i18next';
import { formatAmount } from '@/common/utils';
import { formatWithT } from '@/i18n/utils';
import clsx from 'clsx';
import { InvoicePayment } from '@/common/components/invoice-payment';
import { BusinessUserMembershipDto } from '@/features/memberships/types/BusinessUserMembershipDto';

type Props = {
  membership: BusinessUserMembershipDto & {
    status: MembershipStatusEnum;
  };
};

const MembershipItem = ({ membership }: Props) => {
  const { t } = useTranslation();
  const setPanel = useSetRecoilState(panelState);

  const onPayInvoice = useCallback(
    (invoiceId: string) => {
      setPanel({
        component: InvoicePayment,
        isOpen: true,
        autoHeight: true,
        props: { invoiceId },
      });
    },
    [setPanel],
  );

  const onViewDetails = useCallback(() => {
    setPanel({
      component: MembershipInfoPanel,
      title: t('membershipInfo'),
      borderlessTitle: true,
      showCloseButton: false,
      isOpen: true,
      autoHeight: true,
      props: {
        membership,
      },
    });
  }, [membership, setPanel, t]);

  const descriptionClass = 'text-typo-secondary text-sm';
  return (
    <div
      className={clsx('px-4 py-6', {
        'bg-gray-25': membership.status === MembershipStatusEnum.INACTIVE,
      })}
    >
      <div className="flex justify-between">
        <div className="flex-1 min-w-0 mr-2 break-words">
          <h4 className="font-median">{membership.name}</h4>
          {membership.expiresAt && (
            <>
              {membership.status === MembershipStatusEnum.INACTIVE ? (
                <p className={descriptionClass}>
                  {t('ended')}: {formatWithT(membership.expiresAt, 'dd-MM-yyyy')}
                </p>
              ) : (
                <p className={descriptionClass}>
                  {t('ending')}: {formatWithT(membership.expiresAt, 'dd-MM-yyyy')}
                </p>
              )}
            </>
          )}
        </div>
        <div className="min-w-0 ">
          <MembershipStatus status={membership.status} />
        </div>
      </div>
      <div className={twMerge('flex flex-col min-w-0 mr-2 break-words', descriptionClass)}>
        {membership.nextPaymentAt && (
          <span>
            {t('nextPaymentOn', {
              date: formatWithT(new Date(), 'MMMM dd, yyyy'),
            })}
          </span>
        )}
        {membership.awaitingPayment && membership.initialInvoiceAmount && (
          <span>
            <Trans i18nKey="amountDue" values={{ amount: formatAmount(50) }} components={{ strong: <strong /> }} />
          </span>
        )}
      </div>
      {membership.consumptionDetails && (
        <div className="mt-2">
          <MembershipStampsDetail details={membership.consumptionDetails} />
        </div>
      )}
      <div className="flex flex-col space-y-2 mt-6">
        {membership.status === MembershipStatusEnum.AWAITING_PAYMENT && membership.initialInvoiceId !== null && (
          <Button onClick={() => onPayInvoice(membership.initialInvoiceId)}>{t('payOpenInvoice')}</Button>
        )}

        <Button variant="secondary" onClick={onViewDetails}>
          <span className="text-primary">{t('viewDetails')}</span>
        </Button>
      </div>
    </div>
  );
};

export default MembershipItem;
