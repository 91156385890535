import { useTranslation } from 'react-i18next';
import { useSnack } from '@/common/hooks/use-snack';
import { useRecoilValue } from 'recoil';
import { categoriesFilterState } from '@/web/views/shop/atoms';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useBusiness } from '@/web/hooks/use-business';
import { PaginationState } from '@tanstack/react-table';
import { useQuery, useQueryClient } from 'react-query';
import {
  GET_BUSINESS_WEBSHOP_ITEMS,
  getBusinessWebshopItems,
  updateBusinessWebshopItemClick,
} from '@/web/views/shop/endpoints';
import { NoResultsFound } from '@/common/components/no-results-found';
import { WebshopItemWithId } from '@/web/views/shop/types';
import { GAvatar } from '@/design-system/g-avatar';
import { Title } from '@/design-system/v3/title';
import Text from '@/design-system/v3/text';
import { ArrowSquareOut, Copy } from 'phosphor-react';
import { formatAmount } from '@/common/utils';
import clsx from 'clsx';
import { WebshopCategoriesFilter } from '@/web/views/shop/webshop-categories-filter';
import { ShopContext } from '@/web/views/shop/context';
import { PullToRefresh } from '@/common/components/pull-to-refresh';

export const WebShopTab = () => {
  const { t } = useTranslation();
  const { successMessage } = useSnack();
  const queryClient = useQueryClient();
  const selectedCategories = useRecoilValue(categoriesFilterState);
  const categoriesKeys = useMemo(() => Object.keys(selectedCategories).join(','), [selectedCategories]);
  const { businessUuid, hasPurchasableMemberships } = useBusiness();
  const { setHeader } = useContext(ShopContext);
  const [{ pageIndex, pageSize }] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const { data, isFetching } = useQuery(
    [GET_BUSINESS_WEBSHOP_ITEMS, businessUuid, pageSize, pageIndex, categoriesKeys],
    async () => {
      if (businessUuid) {
        const params = {
          page: pageIndex,
          size: pageSize,
          categories: categoriesKeys,
        };
        return getBusinessWebshopItems(businessUuid, params);
      }

      return null;
    },
  );

  const handleRefresh = useCallback(async () => {
    return queryClient.invalidateQueries([GET_BUSINESS_WEBSHOP_ITEMS, businessUuid]);
  }, [queryClient, businessUuid]);

  useEffect(() => {
    if (hasPurchasableMemberships) {
      setHeader(<WebshopCategoriesFilter />);
    }
  }, [setHeader, hasPurchasableMemberships]);

  if (isFetching) {
    return (
      <div className="grid grid-cols-2 gap-x-4 gap-y-8 p-4">
        <div className="flex items-center justify-center animate-pulse rounded-md bg-surface-tertiary contrast:bg-contrast-surface-tertiary py-8 min-h-[190px]" />
        <div className="flex items-center justify-center animate-pulse rounded-md bg-surface-tertiary contrast:bg-contrast-surface-tertiary py-8 min-h-[190px]" />
        <div className="flex items-center justify-center animate-pulse rounded-md bg-surface-tertiary contrast:bg-contrast-surface-tertiary py-8 min-h-[190px]" />
        <div className="flex items-center justify-center animate-pulse rounded-md bg-surface-tertiary contrast:bg-contrast-surface-tertiary py-8 min-h-[190px]" />
      </div>
    );
  }

  if (!isFetching && !data?.content?.length) {
    return (
      <>
        <div className="flex flex-col items-center justify-center h-contentHeight p-4">
          <NoResultsFound label={t('webshop.noResults')} />
        </div>
      </>
    );
  }

  return (
    <PullToRefresh onRefresh={handleRefresh}>
      {!hasPurchasableMemberships && (
        <div className="px-4 pt-2">
          <WebshopCategoriesFilter />
        </div>
      )}
      <div className="grid grid-cols-2 gap-4 p-4">
        {data?.content?.map((item: WebshopItemWithId) => (
          <a
            href={item.product ? encodeURI(item.link) : undefined}
            target={item.product ? '_blank' : undefined}
            rel="noopener noreferrer"
            key={item.id}
            onClick={async () => {
              if (businessUuid) {
                await updateBusinessWebshopItemClick(businessUuid, item.id);
              }

              if (item.discount?.code) {
                await navigator.clipboard.writeText(item.discount?.code);
                successMessage('copied');
              }
            }}
          >
            <GAvatar
              className="rounded-md bg-surface-tertiary contrast:bg-contrast-surface-tertiary min-h-[190px] w-full"
              path={item.imageUrl}
            />
            <Title as="h2" variant="title4" className="mt-2">
              {item.name}
            </Title>
            <Text variant="small">{getPriceOrDiscount(item)}</Text>
            <div className="mt-2">
              {item.product ? (
                <div className="flex space-x-1 items-center text-primary">
                  <span>{t('webshop.externalLink.text')}</span>
                  <ArrowSquareOut />
                </div>
              ) : (
                <div className="text-primary text-md flex space-x-1 items-center">
                  <span>{item.discount?.code}</span>
                  <Copy />
                </div>
              )}
            </div>
          </a>
        ))}
      </div>
    </PullToRefresh>
  );
};

const getPriceOrDiscount = (item: WebshopItemWithId) => {
  if (item.product?.price) {
    const price = formatAmount(+item.product.price);
    let originalPrice = null;

    if (item.product.originalPrice) {
      originalPrice = (
        <span className="line-through text-typo-secondary">{formatAmount(+item.product.originalPrice)}</span>
      );
    }

    return (
      <div className={clsx('flex', item.product.originalPrice && 'text-green')}>
        {price}
        {originalPrice}
      </div>
    );
  }

  return `${item.discount?.percentage}% Discount`;
};
