import { downloadPicture } from '@/auth/endpoints';
import { useQuery } from 'react-query';
import { twMerge } from 'tailwind-merge';
import GymlySymbol from '@/gymly-symbol.svg?react';
import clsx from 'clsx';
import { useBusiness } from '@/web/hooks/use-business';

type AvatarSize = '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  children?: React.ReactElement;
  path?: string | null;
  letter?: string;
  size?: AvatarSize;
}

export const FETCH_AVATAR_QUERY = 'FETCH_AVATAR_QUERY';
export const GAvatar = ({ children, path, src, className, size = 'md', style, letter, ...props }: Props) => {
  const { businessLogo } = useBusiness();
  const sizes = clsx({
    'w-[92px] h-[92px] min-w-[92px]': size === '3xl',
    'w-20 h-20 min-w-[80px]': size === '2xl',
    'w-16 h-16 min-w-[60px]': size === 'xl',
    'w-12 h-12 min-w-[48px]': size === 'lg',
    'w-10 h-10 min-w-[40px]': size === 'md',
    'w-8 h-8 min-w-[32px]': size === 'sm',
    'w-7 h-7 min-w-[28px]': size === 'xs',
    'w-6 h-6 min-w-[24px]': size === '2xs',
  });
  const classes = twMerge(clsx('relative rounded-full max-w-none overflow-hidden', sizes), className);
  const { data, error, isFetching } = useQuery(
    [FETCH_AVATAR_QUERY, path, src],
    async () => {
      if (path) {
        const imageBlob = await downloadPicture(path);
        const objectUrlFromBlob = URL.createObjectURL(imageBlob);
        return objectUrlFromBlob;
      } else if (src) {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.src = src;

          image.onerror = () => {
            reject(new Error('Image not found'));
          };
          image.onload = () => {
            resolve(src);
          };
        });
      }
    },
    { staleTime: Infinity, enabled: !!path || !!src },
  );

  if (isFetching) {
    return (
      <div
        className={twMerge(
          classes,
          'bg-gray-200 text-typo-secondary contrast:text-contrast-typo-secondary flex items-center justify-center animate-pulse',
        )}
      />
    );
  }

  if (!src && !data) {
    return (
      <div className={twMerge(classes, 'bg-gray-100 text-black-100 flex items-center justify-center')}>
        {children}

        {letter ? (
          <div
            className={clsx('font-semibold text-gray-900', {
              'text-xl': ['3xl', '2xl', 'xl'].includes(size),
              'text-base': ['lg', 'md'].includes(size),
              'text-sm': ['sm', 'xs', '2xs'].includes(size),
            })}
          >
            {letter}
          </div>
        ) : (
          <GymlySymbol className="w-6" />
        )}
      </div>
    );
  }

  return (
    <div
      className={twMerge(
        classes,
        'bg-cover bg-center bg-no-repeat',
        !!error && 'ring-inset ring-1 ring-borders-secondary contrast:ring-contrast-borders-secondary',
      )}
      style={{
        ...style,
        backgroundSize: error ? '70%' : 'cover',
        backgroundImage: `url(${error ? businessLogo : data || src})`,
      }}
      {...props}
    />
  );
};
