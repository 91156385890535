import { useAuth } from '@/auth/hooks/use-auth';
import {
  FETCH_BUSINESS,
  FETCH_BUSINESS_COURSE_TYPES,
  FETCH_BUSINESS_LOCATIONS,
  FETCH_BUSINESS_MEMBERSHIPS,
  getBusiness,
  getBusinessCourseTypes,
  getBusinessLocations,
  getBusinessMembershipTypes,
  getBusinessWithUser,
} from '@/business/endpoints';
import { useQuery } from 'react-query';
import { useBusiness } from '@/web/hooks/use-business';
import {
  businessCourseTypesState,
  businessLocationsState,
  businessMembershipsState,
  selectedBusinessState,
} from '@/business/atoms';
import { useSetRecoilState } from 'recoil';
import { Business, BusinessWithUser } from '@/web/types';
import { GPageLoader } from '@/design-system/v3/g-page-loader';
import { ReactNode, useEffect } from 'react';
import { isCustomApp } from '@/web/utils';
import { usePageContext } from '@/web/hooks/use-page-context';

export const BusinessInit = ({ children }: { children: ReactNode }) => {
  const { isLoggedIn, isUserOnboarded } = useAuth();
  const { businessUuid, setBusinessUuid } = useBusiness();
  const setSelectedBusiness = useSetRecoilState(selectedBusinessState);
  const setBusinessMemberships = useSetRecoilState(businessMembershipsState);
  const setBusinessCourseTypes = useSetRecoilState(businessCourseTypesState);
  const setBusinessLocations = useSetRecoilState(businessLocationsState);
  const { isOnboarding } = usePageContext();

  useEffect(() => {
    if (isCustomApp() && window.AppBusinessUuid) {
      setBusinessUuid(window.AppBusinessUuid);
    }
  }, [setBusinessUuid]);

  const { data: businessMemberships } = useQuery(
    [FETCH_BUSINESS_MEMBERSHIPS, businessUuid],
    async () => {
      if (businessUuid) {
        return getBusinessMembershipTypes(businessUuid);
      }
    },
    {
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    setBusinessMemberships(
      (businessMemberships ?? []).map((membership) => ({
        ...membership,
        label: `${membership.name} (${membership.amountFormatted})`,
        value: membership.id,
      })),
    );
  }, [setBusinessMemberships, businessMemberships]);

  const businessQuery = useQuery([FETCH_BUSINESS, businessUuid, isLoggedIn], async () => {
    if (businessUuid) {
      const business = await getBusiness(businessUuid);
      let businessController: Partial<BusinessWithUser> = {};
      if (isLoggedIn && !isOnboarding) {
        businessController = await getBusinessWithUser(businessUuid);
      }
      const result = { ...business, ...businessController };

      setSelectedBusiness(result as Business & BusinessWithUser);

      return result;
    }
  });

  useQuery(
    [FETCH_BUSINESS_COURSE_TYPES, businessUuid, isLoggedIn, isUserOnboarded],
    async () => {
      if (businessUuid && isLoggedIn && isUserOnboarded) {
        const coursesTypes = await getBusinessCourseTypes(businessUuid);
        const types = coursesTypes?.content
          ? coursesTypes.content.map((courseType: any) => ({
              ...courseType,
              label: courseType.name,
              value: courseType.id,
            }))
          : [];

        setBusinessCourseTypes(types);

        return types;
      }
    },
    {
      staleTime: Infinity,
    },
  );

  useQuery(
    [FETCH_BUSINESS_LOCATIONS, businessUuid],
    async () => {
      if (businessUuid) {
        const locations = await getBusinessLocations(businessUuid);

        setBusinessLocations(
          locations.map((location) => ({
            ...location,
            label: location.name,
            value: location.id,
          })),
        );

        return locations;
      }
    },
    {
      staleTime: Infinity,
    },
  );

  if (businessQuery.isFetching) {
    return <GPageLoader withLogo />;
  }

  return children;
};
