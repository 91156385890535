import Text from '@/design-system/v3/text';
import { Title } from '@/design-system/v3/title';
import GiftIcon from '@/icons/gift.svg?react';

import Shield from '@/common/components/icon/icons/v2/shield.svg?react';
import Chevron from '@/common/components/icon/icons/v2/chevron-left.svg?react';
import ArrowLeft from '@/common/components/icon/icons/v2/arrow-left.svg?react';
import Coin from '@/common/components/icon/icons/v2/coin.svg?react';
import Lock from '@/common/components/icon/icons/v2/lock.svg?react';
import Bill from '@/common/components/icon/icons/v2/bill.svg?react';
import CalendarCheck from '@/common/components/icon/icons/v2/calendar-check.svg?react';
import CalendarClock from '@/common/components/icon/icons/v2/calendar-clock.svg?react';
import Logout from '@/common/components/icon/icons/v2/logout.svg?react';
import Law from '@/common/components/icon/icons/v2/law.svg?react';
import Globe from '@/common/components/icon/icons/v2/globe.svg?react';
import EditIcon from '@/common/components/icon/icons/v2/edit.svg?react';
import WalletIcon from '@/common/components/icon/icons/v2/wallet.svg?react';
import ArrowRightLeftIcon from '@/common/components/icon/icons/v2/arrow-right-left.svg?react';
import { IconWrapper } from '@/common/components/icon-wrapper';
import { useBusiness } from '@/web/hooks/use-business';
import { To, useNavigate } from 'react-router-dom';
import {
  BRING_A_FRIEND_PROFILE_PATH,
  BUSINESS_SWITCH_PATH,
  CHANGE_PASSWORD_PATH,
  getManagePath,
  getProfilePath,
  INVOICES_PATH,
  LANGUAGES_PATH,
  MANAGE_COURSES_PATH,
  PRIVACY_PATH,
  PROFILE_RESERVATIONS_PATH,
  PROFILE_WAITLIST_PATH,
  SUBSCRIPTIONS_PATH,
  TEACHER_COURSES_PATH,
  TERMS_AND_CONDITIONS_PATH,
} from '@/web/routes';
import { LOGOUT_PATH } from '@/auth/routes';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@/auth/hooks/use-auth';
import { useSetRecoilState } from 'recoil';
import { appState } from '@/common/atoms';
import { GChip } from '@/design-system/v3/g-chip';
import React, { ReactNode, useMemo } from 'react';
import { useUpcomingTeacherCourses } from '../teacher-courses';
import { twMerge } from 'tailwind-merge';
import { useTermsAndConditions } from '@/web/hooks/use-terms-and-conditions';
import { useUpcomingReservations } from '@/web/views/reservations';
import { useWaitlist } from '@/web/views/waitlist';
import { GAvatar } from '@/design-system/g-avatar';
import { useQuery } from 'react-query';
import { getUserPosCredit } from '@/web/endpoints';
import { formatAmount } from '@/common/utils';
import { isCustomApp } from '@/web/utils';

type IconType = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

type ExtraMenuItem = {
  icon: IconType | ReactNode;
  title: string;
  badge?: ReactNode;
  endIcon?: ReactNode;
  className?: string;
  onClick: () => void;
};

const ExtraMenuItems = ({
  header,
  items,
  onClose,
}: {
  header?: ReactNode;
  items: ExtraMenuItem[];
  onClose: () => void;
}) => {
  return (
    <div className="bg-white">
      {header && (
        <Title className="p-4" variant="title5">
          {header}
        </Title>
      )}
      <div>
        {items.map((item, index) => (
          <button
            onClick={() => {
              item.onClick();
              onClose();
            }}
            key={`item-${index}`}
            className={twMerge(
              'border-t only:border-b border-borders-secondary contrast:border-contrast-borders-secondary last:border-b w-full flex items-center justify-between p-4',
              item.className,
            )}
          >
            <div className="flex items-center space-x-2">
              {React.isValidElement(item.icon) && item.icon}
              {!React.isValidElement(item.icon) && <IconWrapper icon={item.icon} />}
              <Text variant="small">{item.title}</Text>
            </div>

            <div className="flex space-x-2 items-center">
              {item.badge}
              {!item.endIcon && (
                <IconWrapper
                  icon={Chevron}
                  className="rotate-180 text-typo-secondary contrast:text-contrast-typo-secondary h-5 w-5"
                />
              )}
              {item.endIcon !== undefined && item.endIcon}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

const FETCH_USER_POS_CREDIT = 'FETCH_USER_POS_CREDIT';

export const ExtraMenu = () => {
  const { businessUuid, businessLogo, businessIcon, businessName, businessFeatures } = useBusiness();
  const { t } = useTranslation();
  const { isTeacher, isStaffMember, isAdminMember } = useAuth();
  const setAppState = useSetRecoilState(appState);
  const { nrOfCourses: nrOfReservationCourses } = useUpcomingReservations();
  const { nrOfCourses: nrOfWaitlistCourses } = useWaitlist();
  const { nrOfCourses: nrOfTeachingCourses } = useUpcomingTeacherCourses();
  const navigate = useNavigate();
  const { termsAndConditions, isLoading: isLoadingTermsAndConditions } = useTermsAndConditions();

  const { data: credit, isLoading: isLoadingCredit } = useQuery(
    [FETCH_USER_POS_CREDIT, businessUuid],
    async () => {
      if (!businessUuid || !businessFeatures?.includes('PointOfSale')) {
        return null;
      }

      return getUserPosCredit(businessUuid);
    },
    {
      enabled: !!businessUuid && businessFeatures?.includes('PointOfSale'),
      staleTime: 3600000,
    },
  );

  const items = useMemo<ExtraMenuItem[]>(() => {
    const manageItem: ExtraMenuItem = {
      title: t('extraMenu.editCourses'),
      icon: EditIcon,
      onClick: () => navigate(getManagePath(MANAGE_COURSES_PATH)),
    };

    const teacherClassesItem: ExtraMenuItem = {
      title: t('profileMenuView.yourClasses.title'),
      icon: CalendarCheck,
      badge: nrOfTeachingCourses ? (
        <GChip
          label={nrOfTeachingCourses}
          className="rounded-full bg-primary contrast:bg-contrast-primary text-primary-contrast-text p-0 h-5 w-5 flex items-center justify-center"
        />
      ) : undefined,
      onClick: () => navigate(TEACHER_COURSES_PATH),
    };

    const businessSwitchItem: ExtraMenuItem = {
      title: businessName || '',
      icon: (
        <GAvatar src={businessIcon || businessLogo || undefined} size="2xs" className="rounded-md bg-gray-100 w-6" />
      ),
      endIcon: <ArrowRightLeftIcon className="w-5 h-5 text-secondary" />,
      onClick: () => navigate(getProfilePath(BUSINESS_SWITCH_PATH)),
    };

    const commonItems = [
      ...(credit !== null && credit !== undefined
        ? [
            {
              title: t('profileMenuView.credit.title'),
              icon: WalletIcon,
              endIcon: <span className="text-secondary">{formatAmount(credit, 'EUR')}</span>,
            },
          ]
        : []),
      {
        title: t('profileMenuView.reservations.title'),
        icon: CalendarCheck,
        badge: nrOfReservationCourses ? (
          <GChip
            label={nrOfReservationCourses}
            className="rounded-full bg-primary text-primary-contrast-text contrast:bg-contrast-primary contrast:text-contrast-primary-contrast-text p-0 h-5 w-5 flex items-center justify-center"
          />
        ) : undefined,
        onClick: () => navigate(getProfilePath(PROFILE_RESERVATIONS_PATH)),
      },
      ...(businessFeatures?.includes('BringAFriend')
        ? [
            {
              title: t('bringAFriend.title'),
              icon: GiftIcon,
              onClick: () => navigate(getProfilePath(BRING_A_FRIEND_PROFILE_PATH)),
            },
          ]
        : []),
      {
        title: t('profileMenuView.waitlist.title'),
        icon: CalendarClock,
        badge: nrOfWaitlistCourses ? (
          <GChip
            label={nrOfWaitlistCourses}
            className="rounded-full bg-primary text-primary-contrast-text contrast:bg-contrast-primary contrast:text-contrast-primary-contrast-text p-0 h-5 w-5 flex items-center justify-center"
          />
        ) : undefined,
        onClick: () => navigate(getProfilePath(PROFILE_WAITLIST_PATH)),
      },
      {
        title: t('profileMenuView.subscriptions.title'),
        icon: Coin,
        onClick: () => navigate(getProfilePath(SUBSCRIPTIONS_PATH)),
      },
      {
        title: t('profileMenuView.privacy.title'),
        miao: '',
        icon: Shield,
        onClick: () => navigate(getProfilePath(PRIVACY_PATH)),
      },
      ...(termsAndConditions
        ? [
            {
              title: t('termsAndConditions'),
              icon: Law,
              onClick: () => navigate(getProfilePath(TERMS_AND_CONDITIONS_PATH)),
            },
          ]
        : []),
      {
        title: t('profileMenuView.changePassword.title'),
        icon: Lock,
        onClick: () => navigate(getProfilePath(CHANGE_PASSWORD_PATH)),
      },
      {
        title: t('profileMenuView.yourInvoices.title'),
        icon: Bill,
        onClick: () => navigate(getProfilePath(INVOICES_PATH)),
      },
      {
        title: t('profileMenuView.languages.title'),
        icon: Globe,
        onClick: () => navigate(getProfilePath(LANGUAGES_PATH)),
      },
      {
        title: t('profileMenuView.logout.title'),
        className: 'text-interactive-red',
        icon: Logout,
        onClick: () => navigate(LOGOUT_PATH),
      },
    ];

    const getItems = (items: Array<ExtraMenuItem | Object>) => {
      if (isCustomApp()) {
        return items;
      }

      return [businessSwitchItem, ...items];
    };

    if (isTeacher) {
      return getItems([teacherClassesItem, manageItem, ...commonItems]);
    }

    if (isStaffMember || isAdminMember) {
      return getItems([manageItem, teacherClassesItem, ...commonItems]);
    }

    return getItems(commonItems);
  }, [
    t,
    businessName,
    isAdminMember,
    isStaffMember,
    isTeacher,
    navigate,
    credit,
    nrOfTeachingCourses,
    termsAndConditions,
    nrOfWaitlistCourses,
    nrOfReservationCourses,
    businessIcon,
    businessLogo,
    businessFeatures,
  ]);

  const handleClose = () => setAppState((prev) => ({ ...prev, extraMenuOpen: false }));

  if (isLoadingTermsAndConditions || isLoadingCredit) {
    return;
  }

  return <ExtraMenuItems items={items} onClose={handleClose} />;
};

export const BackToButton = ({ path, label }: { path?: To; label?: string }) => {
  const navigate = useNavigate();

  if (label) {
    return (
      <button className="flex items-center space-x-2" onClick={() => (path ? navigate(path) : navigate(-1))}>
        <IconWrapper icon={ArrowLeft} className="w-6 h-6" />
        <span className="text-base font-neutral">{label}</span>
      </button>
    );
  }

  return (
    <button className="w-6 h-6 flex" onClick={() => (path ? navigate(path) : navigate(-1))}>
      <IconWrapper icon={ArrowLeft} />
    </button>
  );
};
