import React, { useMemo } from 'react';
import { MembershipStatus as MembershipStatusEnum } from '../../enums/MembershipStatus.enum';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

type Props = {
  status: MembershipStatusEnum;
};

const WARNING_STATUSES = [
  MembershipStatusEnum.AWAITING_PAYMENT,
  MembershipStatusEnum.PAUSED,
  MembershipStatusEnum.PENDING,
];

const MembershipStatus = ({ status }: Props) => {
  const { t } = useTranslation();

  const getStatusTranslationLabel = useMemo(() => {
    switch (status) {
      case MembershipStatusEnum.ACTIVE:
        return t('active');
      case MembershipStatusEnum.PAUSED:
        return t('paused');
      case MembershipStatusEnum.AWAITING_PAYMENT:
        return t('awaitingPayment');
      case MembershipStatusEnum.INACTIVE:
        return t('inactive');
      case MembershipStatusEnum.PENDING:
        return t('pending');
      default:
        return '';
    }
  }, [status, t]);

  return (
    <div className="flex items-center border border-gray-300 text-sm text-gray-700 font-medium px-2 py-0.5 rounded-lg w-fit">
      <div
        className={clsx('w-1.5 h-1.5 me-2 rounded-full', {
          'bg-success-500': status === MembershipStatusEnum.ACTIVE,
          'bg-warning-400': WARNING_STATUSES.includes(status),
          'bg-typo-negative': status === MembershipStatusEnum.INACTIVE,
        })}
      />
      {getStatusTranslationLabel}
    </div>
  );
};

export default MembershipStatus;
