import { Goals, Language, User } from '@/auth/types';
import { gaxios } from '@/api';
import { Notification } from './views/inbox/types';
import { Paginator } from '../common/types';
import {
  BusinessUser,
  CheckinFailureReason,
  CheckinFailureReasons,
  Course,
  CourseDetail,
  CourseDetailMember,
  CourseDetailWithHelpers,
  CourseMemberDetails,
  CourseType,
  CourseWithHelpers,
  Invoice,
  Membership,
  PaymentSession,
  UserDataSettings,
} from './types';
import { createSearchParams } from 'react-router-dom';
import { addHelpersToCourse, addHelpersToCourses } from './utils';
import { DEFAULT_TABLE_ROW_SIZE } from '@/common/constants';

type PaginateParams<T> = T & {
  page?: number;
  size?: number;
};

export const FETCH_USER = 'FETCH_USER';

export const getUser = async (): Promise<User> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/v1/user/me`,
  });

  return resp.data;
};

export const FETCH_COURSE = 'FETCH_COURSE';
export const FETCH_COURSES = 'FETCH_COURSES';
export const FETCH_TEACHER_CLASSES = 'FETCH_TEACHER_CLASSES';
export const getUserCourses = async (
  businessUuid: string,
  activities: Record<string, CourseType>,
  params = {},
): Promise<CourseWithHelpers[]> => {
  const requestParams = {
    size: DEFAULT_TABLE_ROW_SIZE,
    ...params,
  };

  const resp = await gaxios({
    method: 'GET',
    url: `/v1/businesses/${businessUuid}/activity-events`,
    params: requestParams,
  });

  return addHelpersToCourses(resp.data, activities);
};

export const getPagedUserCourses = async (
  businessUuid: string,
  activities: Record<string, CourseType>,
  page: number,
  params = {},
): Promise<Paginator<CourseWithHelpers>> => {
  const requestParams = {
    page,
    size: DEFAULT_TABLE_ROW_SIZE,
    ...params,
  };

  const resp = await gaxios<Paginator<Course>>({
    method: 'GET',
    url: `/v1/businesses/${businessUuid}/activity-events/paged`,
    params: requestParams,
  });

  return {
    ...resp.data,
    content: addHelpersToCourses(resp.data.content, activities),
  };
};

export const updateUser = async (data: User | Partial<User>): Promise<User> => {
  const resp = await gaxios({
    method: 'PUT',
    url: `/v1/user/me`,

    data,
  });

  return resp.data;
};

export const updateUserLanguage = async (language: Language): Promise<unknown> => {
  const resp = await gaxios({
    method: 'PUT',
    url: `/v1/user/me/language`,
    data: { language },
  });

  return resp.data;
};

export const updateUserPrivacyPreferences = async (data: {
  anonymous: boolean;
  showPicture: boolean;
  showFullName: boolean;
}): Promise<{
  anonymous: boolean;
  showPicture: boolean;
  showFullName: boolean;
}> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/v1/user/privacy-preferences`,
    data,
  });

  return resp.data;
};

export const subscribeToCourse = async (courseId: string, date: string): Promise<User> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/v1/courses/${courseId}/subscribe`,

    data: { date },
  });

  return resp.data;
};

export const purchaseEvent = async (businessId: string, eventId: string): Promise<PaymentSession | null> => {
  const resp = await gaxios<{ payment: PaymentSession | null }>({
    method: 'POST',
    url: `/v1/businesses/${businessId}/activity-events/${eventId}/purchase`,
  });

  return resp.data.payment;
};

export const joinWaitlist = async (courseId: string, date: string): Promise<User> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/v1/courses/${courseId}/waitlist`,

    data: { date },
  });

  return resp.data;
};

export const getPaginatedUserInvoices = async ({
  page = 0,
  size = 20,
  businessUuid,
}: PaginateParams<{
  businessUuid: string;
}>): Promise<Paginator<Invoice>> => {
  const params: {
    page: string;
    size: string;
  } = {
    page: page.toString(),
    size: size.toString(),
  };

  const query = createSearchParams(params);
  const resp = await gaxios<Paginator<Invoice>>({
    method: 'GET',
    url: `/v1/user/businesses/${businessUuid}/invoices?${query}`,
  });

  return resp.data;
};

export const checkinAttendees = async (courseId: string, date: string, attendees: string[]): Promise<string[]> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/v1/courses/${courseId}/attendance`,
    data: { date, attendees },
  });

  return resp.data;
};

export const unsubscribeFromCourse = async (courseId: string, date: string): Promise<User> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/v1/courses/${courseId}/unsubscribe`,

    data: { date },
  });

  return resp.data;
};

export const uploadUserProfileImage = async (file: File): Promise<User> => {
  const formData = new FormData();
  formData.append('file', file);

  const resp = await gaxios({
    method: 'POST',
    url: `/v1/user/me/profileImage`,

    data: formData,
  });

  return resp.data;
};

/**
 * Change the user's email address.
 *
 * @param email The new email address.
 */
export const changeEmail = async (email: string): Promise<null> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/v1/user/me/email`,
    data: { email },
  });

  return resp.data;
};

export const FETCH_USER_NOTIFICATIONS = 'FETCH_USER_NOTIFICATIONS';
export const getUserNotifications = async (uuid: string, params = {}): Promise<Paginator<Notification>> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/v1/user/businesses/${uuid}/notifications`,

    params,
  });

  return resp.data;
};

export const readUserNotification = async (uuid: string, notificationId: string): Promise<any> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/v1/user/businesses/${uuid}/notifications/${notificationId}/read`,
  });

  return resp.data;
};

export const getQRCode = async (businessUuid: string): Promise<string> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/v1/businesses/${businessUuid}/qr-code`,
    responseType: 'blob',
  });

  return URL.createObjectURL(resp.data);
};

export const checkInMock = async (businessUuid: string, locationUuid: string): Promise<string> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/v1/businesses/${businessUuid}/checkin/${locationUuid}/mock`,
  });

  return resp.data;
};

export const FETCH_UPCOMING_COURSES = 'FETCH_UPCOMING_COURSES';
export const getUserUpcomingCourses = async (
  businessUuid: string,
  activities: Record<string, CourseType>,
  params = {},
): Promise<CourseMemberDetails[]> => {
  const requestParams = {
    size: 1000,
    page: 0,
    ...params,
  };

  const resp = await gaxios({
    method: 'GET',
    url: `/v1/user/businesses/${businessUuid}/courses/upcoming`,
    params: requestParams,
  });

  return resp.data?.content?.map((memberDetails: CourseMemberDetails) => {
    return {
      courseDetails: addHelpersToCourse(memberDetails.courseDetails, activities),
      status: memberDetails.status,
    };
  });
};

export const FETCH_COURSE_DETAIL_QUERY = 'FETCH_COURSE_DETAIL';
export const getEvent = async (
  businessId: string,
  eventId: string,
  activities: Record<string, CourseType>,
): Promise<CourseDetailWithHelpers> => {
  const resp = await gaxios<CourseDetail>({
    method: 'GET',
    url: `/v1/businesses/${businessId}/activity-events/${eventId}`,
  });

  return addHelpersToCourse(resp.data, activities) as CourseDetailWithHelpers;
};

export const FETCH_COURSE_MEMBERS_QUERY = 'FETCH_COURSE_MEMBERS';
export const getCourseMembers = async (courseId: string, date: string): Promise<CourseDetailMember[]> => {
  const resp = await gaxios<CourseDetailMember[]>({
    method: 'GET',
    url: `/v1/courses/${courseId}/members`,
    params: { date },
  });

  return resp.data;
};

export const getUserGoals = async (): Promise<Goals> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/v1/user/goals`,
  });

  return resp.data;
};

export const setUserGoals = async (data: Goals): Promise<Goals> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/v1/user/goals`,
    data,
  });

  return resp.data;
};

export const getCourseTypes = async (businessId: string, page = 0, size = 20): Promise<Paginator<CourseType>> => {
  const params: {
    page: string;
    size: string;
  } = {
    page: page.toString(),
    size: size.toString(),
  };

  const query = createSearchParams(params);

  const resp = await gaxios({
    method: 'GET',
    url: `/v1/businesses/${businessId}/course-types?${query}`,
  });

  return resp.data;
};

export const getCourseType = async (businessId: string, id: string): Promise<CourseType> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/v1/staff/businesses/${businessId}/courseTypes/${id}`,
  });

  return resp.data;
};

export const updateCourseType = async (
  businessId: string,
  id: string,
  data: {
    name: string;
    description: string;
  },
): Promise<any> => {
  const resp = await gaxios({
    method: 'PUT',
    url: `/v1/staff/businesses/${businessId}/courseTypes/${id}`,
    data,
  });

  return resp.data;
};

export const getUserDataSettings = async (businessId: string): Promise<{ settings: UserDataSettings }> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/v1/businesses/${businessId}/users/data-settings`,
  });

  return resp.data.settings;
};

export const getMembershipDetail = async (businessId: string, membershipId: string): Promise<Membership> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/v1/businesses/${businessId}/memberships/${membershipId}`,
  });

  return resp.data;
};

/**
 * Get checkin information for the user for a business.
 *
 * @param businessId
 */
export const getBusinessCheckinInfo = async (
  businessId: string,
): Promise<{ failureReasons: CheckinFailureReasons[] }> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/v2/user/businesses/${businessId}/checkin`,
  });

  return resp.data;
};

export const getBusinessUsers = async (uuid: string, params = {}): Promise<Paginator<BusinessUser>> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/v1/user/businesses/${uuid}/users`,
    params: {
      size: DEFAULT_TABLE_ROW_SIZE,
      ...params,
    },
  });

  return resp.data;
};

export const addAttendeeToCourse = async (
  courseId: string,
  date: string,
  attendeeId: string,
): Promise<CourseDetail> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/v1/courses/${courseId}/attendees`,
    data: { date, attendees: [attendeeId] },
  });

  return resp.data;
};

export const deleteAttendeeFromCourse = async (
  courseId: string,
  date: string,
  attendeeId: string,
): Promise<CourseDetail> => {
  const resp = await gaxios({
    method: 'DELETE',
    url: `/v1/courses/${courseId}/attendees/${attendeeId}`,
    params: { date },
  });

  return resp.data;
};

export const cancelCourse = async (businessId: string, course: any, data = {}) => {
  const resp = await gaxios({
    method: 'POST',
    url: `/v1/staff/businesses/${businessId}/locations/${course.businessLocation.id}/courses/${course.id}/cancel`,
    data,
  });

  return resp.data;
};

export const getUserPosCredit = async (businessId: string): Promise<number> => {
  const resp = await gaxios<{ amount: number }>({
    method: 'GET',
    url: `/v1/user/businesses/${businessId}/pos/credit`,
  });

  return resp.data.amount;
};
