import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { appState } from '@/common/atoms';
import { atom, useRecoilState } from 'recoil';
import { getFromStorage, setInStorage } from '@/common/utils';

export const useEmbed = () => {
  const location = useLocation();
  const [storedEmbed, setStoredEmbed] = useRecoilState(embedAtom);
  const [appStateValue, setAppState] = useRecoilState(appState);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);

    if (urlParams.has('embed')) {
      setStoredEmbed(true);
      setAppState((prev) => ({ ...prev, embed: true }));
    }
  }, [location, setAppState, setStoredEmbed, storedEmbed]);

  return storedEmbed || appStateValue.embed;
};

const embedAtom = atom<boolean | null>({
  key: 'embedMode',
  default: getFromStorage('gymly.embedMode') || null,
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        if (newValue) {
          setInStorage('gymly.embedMode', newValue);
        }
      });
    },
  ],
});
