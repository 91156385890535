import { gaxios } from '@/api';
import { Calendar } from '@/features/calender/types/Calendar';

export const FETCH_CALENDARS = 'FETCH_CALENDARS';

export const getCalendars = async (businessId: string): Promise<{ calendars: Calendar[] }> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/v1/businesses/${businessId}/calendars`,
  });

  return resp.data;
};
