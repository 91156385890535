import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import Button from '@/design-system/v3/button';
import Text from '@/design-system/v3/text';
import { Title } from '@/design-system/v3/title';
import Upload from '@/common/components/icon/icons/v2/upload.svg?react';
import Camera from '@/common/components/icon/icons/v2/camera-photo.svg?react';
import Image from '@/common/components/icon/icons/v2/image.svg?react';
import { IconWrapper } from '@/common/components/icon-wrapper';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getUser, updateUserPrivacyPreferences, uploadUserProfileImage } from '@/web/endpoints';
import { authUserState } from '@/auth/atoms';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useSnack } from '@/common/hooks/use-snack';
import { resizeImage } from '@/common/utils';
import { RadioGroup } from '@headlessui/react';
import clsx from 'clsx';
import { GSwitch } from '@/design-system/v3/g-switch';
import { GLink } from '@/design-system/v3/g-link';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { usePrivacyPolicy } from '@/web/hooks/use-privacy-policy';
import { getProfilePath, PRIVACY_POLICY_PATH } from '@/web/routes';
import { useEmbed } from '@/common/hooks/use-embed';
import { MEDIA_PRIVACY_PATH } from '@/auth/routes';

const mediaState = atom<File | null>({
  key: 'mediaState',
  default: null,
});

const namePrivacyState = atom<string>({
  key: 'privacyState',
  default: 'showFullName',
});

const picturePrivacyState = atom<boolean>({
  key: 'picturePrivacyState',
  default: true,
});

export const MediaPrivacyView = () => {
  const user = useRecoilValue(authUserState);
  const content = user?.profileImagePath ? <PrivacyContent /> : <MediaContent />;
  const footer = user?.profileImagePath ? <PrivacyFooter /> : <MediaFooter />;
  const title = user?.profileImagePath ? 'Step 2/2' : 'Step 1/2';
  return (
    <PageWrapper
      header={<PageHeader title={title} />}
      content={content}
      footerClasses="px-4"
      footer={footer}
      embedFooter
      embedHeader
    />
  );
};

const MediaContent = () => {
  const { t } = useTranslation();
  const user = useRecoilValue(authUserState);
  const [preview, setPreview] = useState<string | null>(user?.profileImageUrl || null);
  const [, setImage] = useRecoilState(mediaState);
  const el = useRef<HTMLInputElement | null>(null);
  const embed = useEmbed();

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      setPreview(URL.createObjectURL(file));
      setImage(file);
    }
  };

  const triggerImageUpload = () => {
    if (el.current) {
      if (el.current.hasAttribute('capture')) {
        el.current.removeAttribute('capture');
      }
      el.current.click();
    }
  };

  const triggerSelfie = () => {
    if (el.current) {
      el.current.setAttribute('capture', 'user');
      el.current.click();
    }
  };

  return (
    <div>
      <div
        className="flex items-center justify-center rounded-full w-20 h-20 my-4 bg-center bg-cover bg-gray-200"
        style={preview ? { backgroundImage: `url(${preview}` } : undefined}
      >
        {!preview && <IconWrapper icon={Image} />}
      </div>
      <Title variant="title3">{t('mediaContent.sayCheese')}</Title>
      <Text variant="small" className="text-gray-500">
        {t('mediaContent.picture.subtitle')}
      </Text>
      <div className="space-y-2 mt-4">
        <input ref={el} type="file" accept="image/*" id="uploadImage" className="hidden" onChange={handleImageChange} />
        <Button
          variant="secondary"
          onClick={triggerImageUpload}
          gsize="large"
          className="justify-start"
          startIcon={<IconWrapper icon={Upload} />}
        >
          {t('uploadPicture')}
        </Button>
        {!('ReactNativeWebView' in window) && !embed && (
          <Button
            variant="secondary"
            onClick={triggerSelfie}
            gsize="large"
            className="justify-start"
            startIcon={<IconWrapper icon={Camera} />}
          >
            {t('takePicture')}
          </Button>
        )}
      </div>
    </div>
  );
};

const MediaFooter = () => {
  const { t } = useTranslation();
  const [image] = useRecoilState(mediaState);
  const setAuthUser = useSetRecoilState(authUserState);
  const [loading, setLoading] = useState(false);
  const { errorMessage } = useSnack();

  const onUpload = async () => {
    if (image) {
      try {
        setLoading(true);
        const resized = await resizeImage(image);
        const profile = await uploadUserProfileImage(resized);

        setAuthUser(profile);
      } catch (e) {
        if (e instanceof Error) errorMessage(e.message);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <Button loading={loading} disabled={!image} onClick={onUpload}>
      {t('nextStep')}
    </Button>
  );
};

export const PrivacyFooter = ({ btnContent }: { btnContent?: ReactNode }) => {
  const { t } = useTranslation();
  const { successMessage } = useSnack();
  const [loading, setLoading] = useState(false);
  const setUser = useSetRecoilState(authUserState);
  const nameState = useRecoilValue(namePrivacyState);
  const showPicture = useRecoilValue(picturePrivacyState);
  const navigate = useNavigate();
  const location = useLocation();
  const isOnboardingMediaPrivacy = matchPath(MEDIA_PRIVACY_PATH, location.pathname);

  const onUpload = async () => {
    try {
      setLoading(true);
      await updateUserPrivacyPreferences({
        anonymous: nameState === 'anonymous',
        showFullName: nameState === 'showFullName',
        showPicture,
      });

      const user = await getUser();
      setUser(user);
      successMessage('saved');

      if (isOnboardingMediaPrivacy) {
        navigate('/');
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <Button loading={loading} onClick={onUpload}>
      {btnContent || t('save')}
    </Button>
  );
};

const namePrivacyOptions = (t: TFunction<'translations'>) => [
  {
    label: (
      <div>
        <Text>{t('anonymous')}</Text>
        <Text variant="small" className="text-typo-secondary contrast:text-contrast-typo-secondary">
          {t('privacyView.fullName.hide')}
        </Text>
      </div>
    ),
    value: 'anonymous',
  },
  {
    label: (
      <div>
        <Text>{t('privacyView.firstName.title')}</Text>
        <Text variant="small" className="text-typo-secondary contrast:text-contrast-typo-secondary">
          {t('privacyView.firstName.subtitle')}
        </Text>
      </div>
    ),
    value: 'firstNameOnly',
  },
  {
    label: (
      <div>
        <Text>{t('privacyView.fullName.title')}</Text>
        <Text variant="small" className="text-typo-secondary contrast:text-contrast-typo-secondary">
          {t('privacyView.fullName.subtitle')}
        </Text>
      </div>
    ),
    value: 'showFullName',
  },
];

export const PrivacyContent = ({ explanation }: { explanation?: ReactNode }) => {
  const { t } = useTranslation();
  const user = useRecoilValue(authUserState);
  const navigate = useNavigate();
  const [nameState, setNameState] = useRecoilState(namePrivacyState);
  const [pictureState, setPictureState] = useRecoilState(picturePrivacyState);
  const privacyPolicy = usePrivacyPolicy();

  useEffect(() => {
    if (user?.privacyPreferences) {
      setNameState(
        user.privacyPreferences.anonymous
          ? 'anonymous'
          : user.privacyPreferences.showFullName
            ? 'showFullName'
            : 'firstNameOnly',
      );
      setPictureState(user.privacyPreferences.showPicture);
    }
  }, [setNameState, setPictureState, user]);

  const handlePrivacyPolicyClick = useCallback(() => {
    navigate(getProfilePath(PRIVACY_POLICY_PATH));
  }, [navigate]);

  const privacyPolicyButton = useMemo(() => {
    if (privacyPolicy.privacyPolicy) {
      return (
        <button className="inline-flex text-secondary underline outline-none" onClick={handlePrivacyPolicyClick}>
          {t('privacyPolicy')}
        </button>
      );
    }

    return (
      <GLink to={privacyPolicy.url as string} rel="noopener noreferrer" target="_blank">
        {t('privacyPolicy')}
      </GLink>
    );
  }, [t, privacyPolicy, handlePrivacyPolicyClick]);

  return (
    <div>
      <div
        className="flex items-center justify-center rounded-full w-20 h-20 my-4 bg-center bg-cover bg-gray-200"
        style={{ backgroundImage: `url(${user?.profileImageUrl}` }}
      ></div>
      <div className="mb-5">
        <Title variant="title3">{t('privacyView.settings.title')}</Title>
        <Text variant="small" className="text-typo-secondary contrast:text-contrast-typo-secondary">
          {explanation || t('privacyView.explanationWithRoute')}
        </Text>
      </div>

      <div className="mb-5">
        <div className="mt-4">
          <RadioGroup
            value={nameState}
            onChange={setNameState}
            className="border border-borders-secondary contrast:border-contrast-secondary rounded-xl"
          >
            {namePrivacyOptions(t).map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option.value}
                className="flex gap-4 p-4 border-t first:border-t-0 border-borders-secondary"
              >
                {({ checked }) => (
                  <>
                    <div
                      className={clsx(
                        'flex items-center w-5 h-5 min-w-[20px] mt-1 justify-center rounded-full ring-inset ring-1 ring-gray dark:ring-white',
                        checked ? 'bg-primary' : 'bg-white',
                      )}
                    >
                      {checked && <div className="w-2 h-2 min-h-[8px] bg-white rounded-full" />}
                    </div>
                    <div className={clsx(' text-typo-primary', checked ? 'font-semibold' : 'font-normal')}>
                      {option.label}
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
      </div>

      <div>
        <label htmlFor="publicProfilePic" className="flex justify-between items-center">
          <div>
            <Text>{t('privacyView.picture.title')}</Text>
            <Text variant="small" className="text-typo-secondary contrast:text-contrast-typo-secondary">
              {t('privacyView.picture.subtitle')}
            </Text>
          </div>
          <GSwitch id="publicProfilePic" checked={pictureState} onChange={(ev) => setPictureState(ev.target.checked)} />
        </label>
      </div>

      <div className="flex my-4">{privacyPolicyButton}</div>
    </div>
  );
};
