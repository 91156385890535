import { GPanel } from '@/design-system/v3/g-panel';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Textarea } from '@/design-system/v3/textarea';
import { Course, CourseDetailWithHelpers } from '@/web/types';
import Button from '@/design-system/v3/button';
import { updateBusinessCourse } from '@/business/endpoints';
import { useBusiness } from '@/web/hooks/use-business';
import { add, addYears, parseISO } from 'date-fns';
import { courseToForm, dateWithTime, formatCourseToUtc, handleValidationError, parseCourseISO } from '@/common/utils';
import { useRecoilValue } from 'recoil';
import { businessCourseTypesState } from '@/business/atoms';
import { GFormControlLabel } from '@/design-system/v3/g-form-control-label';
import { useSnack } from '@/common/hooks/use-snack';
import { FETCH_COURSE_DETAIL_QUERY } from '@/web/endpoints';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { COURSES_PATH } from '@/web/routes';
import { useTranslation } from 'react-i18next';
import { PanelNavItem } from '@/common/components/PanelNavItem';
import TextEditIcon from '@/icons/text-edit.svg?react';
import PeopleAddIcon from '@/icons/people-add.svg?react';
import ArrowRotateRightLeftIcon from '@/icons/arrow-rotate-right-left.svg?react';
import XIcon from '@/icons/x.svg?react';
import { AddMemberMode } from '@/web/views/course-detail/course-options-menu/AddMemberMode';
import { ChangeTeacherMode } from '@/web/views/course-detail/course-options-menu/ChangeTeacherMode';
import { CourseOptionsMenuContext } from '@/web/views/course-detail/course-options-menu/context';
import { CancelMode } from '@/web/views/course-detail/course-options-menu/CancelMode';

type Props = {
  course: CourseDetailWithHelpers;
  open: boolean;
  onClose: () => void;
  initialMode?: MenuMode | null;
};

export enum MenuMode {
  EDIT_DESCRIPTION = 'EDIT_DESCRIPTION',
  ADD_MEMBER = 'ADD_MEMBER',
  CHANGE_TEACHER = 'CHANGE_TEACHER',
  CANCEL = 'CANCEL',
}

export type CourseForm = {
  description: string;
};

export const CourseOptionsMenu = ({ course, open, onClose, initialMode = null }: Props) => {
  const { t } = useTranslation();
  const { businessUuid } = useBusiness();
  const { errorMessage } = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const courseTypes = useRecoilValue(businessCourseTypesState);
  const [actions, setActions] = useState<ReactNode | null>(null);
  const [mode, setMode] = useState<MenuMode | null>(initialMode);

  useEffect(() => {
    if (mode === null) {
      setActions(null);
    }
  }, [mode]);

  useEffect(() => {
    if (open) {
      setMode(initialMode);
    }
  }, [open, initialMode]);

  const {
    handleSubmit,
    control,
    setError,
    formState: { isSubmitting, isLoading },
  } = useForm<CourseForm>({
    defaultValues: {
      description: course.description || '',
    },
  });

  const contextValue = useMemo(
    () => ({
      course,
      courseDate: parseCourseISO(course.originalStartAt || course.startAt, course),
      setActions,
      onClose,
    }),
    [course, onClose],
  );

  const options = [
    {
      label: t('courseOptionsMenu.editDescription'),
      icon: TextEditIcon,
      onClick: () => setMode(MenuMode.EDIT_DESCRIPTION),
    },
    {
      label: t('courseOptionsMenu.addMember'),
      icon: PeopleAddIcon,
      onClick: () => setMode(MenuMode.ADD_MEMBER),
    },
    {
      label: t('courseOptionsMenu.changeTeacher'),
      icon: ArrowRotateRightLeftIcon,
      onClick: () => setMode(MenuMode.CHANGE_TEACHER),
    },
    {
      label: t('courseOptionsMenu.cancelCourse'),
      icon: XIcon,
      onClick: () => setMode(MenuMode.CANCEL),
    },
  ];

  const handleClose = () => {
    setMode(null);
    onClose();
  };

  const handleUpdateDescription = async (formData: CourseForm) => {
    if (!businessUuid) {
      return;
    }

    const data: any = courseToForm(course);

    const startAt = dateWithTime(data.date, data.from);
    data.startAt = formatCourseToUtc(startAt, course);
    data.originalStartAt = course.originalStartAt;
    data.utcOffset = course.utcOffset;

    const endAt = add(startAt, { minutes: data.duration });
    data.endAt = formatCourseToUtc(endAt, course);

    if (data.repeat !== 'ONCE') {
      data.recurringEndDate = addYears(parseISO(data.endAt), 1).toISOString();
      data.recurringPattern = {
        recurringType: data.repeat,
        daysOfWeek: [data.date.getDay()],
      };

      delete data.repeat;
    }

    data.teachers = data.teacherIds.map((id: string) => ({ id }));
    data.description = formData.description;
    data.capacity = parseInt(data.capacity);
    data.recurringUpdateMode = 'SINGLE';

    let response: Course;

    try {
      response = await updateBusinessCourse(businessUuid, course.id, data);
    } catch (e) {
      return handleValidationError(e, setError, errorMessage);
    }

    queryClient.invalidateQueries([FETCH_COURSE_DETAIL_QUERY, course.id]);
    handleClose();
    navigate(`${COURSES_PATH}/${response.id}/${course.originalStartAt}`);
  };

  return (
    <GPanel open={open} className="h-auto" childrenClassName="px-0" onClose={handleClose} actions={actions}>
      <CourseOptionsMenuContext.Provider value={contextValue}>
        {mode === null && (
          <div className="px-4">
            {options.map((option, index) => {
              const Icon = option.icon;

              return <PanelNavItem key={index} label={option.label} icon={<Icon />} onClick={option.onClick} />;
            })}
          </div>
        )}

        {mode === MenuMode.ADD_MEMBER && <AddMemberMode />}

        {mode === MenuMode.CHANGE_TEACHER && <ChangeTeacherMode />}

        {mode === MenuMode.CANCEL && <CancelMode />}

        {mode === MenuMode.EDIT_DESCRIPTION && (
          <div className="px-4 space-y-4">
            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, value } }) => (
                <div>
                  <GFormControlLabel
                    control={
                      <Textarea
                        placeholder={t('courseType.descriptionPlaceholder')}
                        onChange={onChange}
                        value={value}
                        className="min-h-[160px]"
                        initialValue={course.description || undefined}
                      />
                    }
                    label="Description"
                  />
                  <span className="inline-flex mt-1 text-sm text-gray">{t('courseOptionsMenu.descriptionHint')}</span>
                </div>
              )}
            />
            <Button
              variant="primary"
              onClick={handleSubmit(handleUpdateDescription)}
              loading={isSubmitting || isLoading}
            >
              {t('confirm')}
            </Button>
          </div>
        )}
      </CourseOptionsMenuContext.Provider>
    </GPanel>
  );
};
