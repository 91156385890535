import { gaxios } from '@/api';
import { BusinessUserMembershipDto } from '@/features/memberships/types/BusinessUserMembershipDto';

export const FETCH_BUSINESS_USER_MEMBERSHIPS = 'FETCH_BUSINESS_USER_MEMBERSHIPS';

export const getBusinessUserMemberships = async (businessId: string): Promise<BusinessUserMembershipDto[]> => {
  const resp = await gaxios<{ content: BusinessUserMembershipDto[] }>({
    method: 'GET',
    url: `/v1/users/me/businesses/${businessId}/memberships`,
  });

  return resp.data.content;
};
