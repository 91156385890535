import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { autoUpdate, flip, FloatingPortal, offset, Placement, shift, size, useFloating } from '@floating-ui/react';
import { X } from 'phosphor-react';
import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { HapticFeedbackType, triggerHapticFeedback } from '@/common/native-bridge/utils';

export interface Option {
  label?: string;
  component?: ReactNode;
  value: string;
}

type Size = 'regular' | 'small';
export interface SelectProps {
  title?: string;
  error?: string;
  helperText?: ReactNode;
  gsize?: Size;
  placeholder?: string;
  disabled?: boolean;
  options: Option[];
  className?: string;
  placement?: Placement;
  multiple?: boolean;
  required?: boolean;
  optionalLabel?: boolean;
  onChange: (e: any) => void;
  value: string | string[] | null;
}

export const GSelect = ({
  title,
  helperText,
  placeholder,
  error,
  options,
  disabled,
  required,
  optionalLabel,
  gsize = 'regular',
  placement = 'bottom-end',
  className,
  multiple = false,
  onChange,
  value,
}: SelectProps) => {
  const { t } = useTranslation();
  const { refs, floatingStyles } = useFloating({
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [
      flip({ fallbackAxisSideDirection: 'end' }),
      shift(),
      offset(10),
      size({
        apply({ elements }) {
          Object.assign(elements.floating.style, {
            width: `${elements.reference.getBoundingClientRect().width}px`,
          });
        },
      }),
    ],
  });
  const [selectedItem, setSelected] = useState<string | string[] | null>(value || []);
  const btnClasses = clsx(
    'pl-3 pr-10 transition-all flex items-center w-full whitespace-nowrap rounded-md text-typo-primary contrast:text-contrast-typo-primary dark:text-black-10 shadow-input relative bg-white dark:bg-black-100 text-left border',
    error
      ? 'border-error-300 focus:border-error-300 focus:shadow-inputFocusError'
      : 'border-gray-300 focus:shadow-inputFocus focus:border-primary-300',

    {
      'h-10 text-base': gsize === 'regular' && !multiple,
      'h-8 text-sm': gsize === 'small' && !multiple,
      'min-h-[40px] text-base': multiple && gsize === 'regular',
      'min-h-[32px] text-sm': multiple && gsize === 'small',
      'py-1': multiple,
      'bg-gray-100 opacity-50 cursor-default focus:shadow-none transition-none': disabled,
    },
    'focus:outline-none focus:ring-0 focus:ring-offset-0 focus:text-typo-primary contrast:focus:text-contrast-typo-primary dark:focus:text-black-10 focus:border-gray-300',
  );

  const onRemoveItem = useCallback(
    (value: string) => {
      if (Array.isArray(selectedItem)) {
        const itemIndex = selectedItem.findIndex((option) => option === value);

        if (itemIndex > -1) {
          const copy = [...selectedItem];

          copy.splice(itemIndex, 1);

          setSelected(copy);
          onChange({
            target: {
              value: copy,
            },
          });
        }
      }
    },
    [selectedItem, setSelected, onChange],
  );

  const isSelected = useCallback(
    (optionValue: string) => {
      if (Array.isArray(selectedItem)) {
        return selectedItem.findIndex((item) => item === optionValue) > -1;
      }

      return optionValue === selectedItem;
    },
    [selectedItem],
  );

  const getSelectedComponent = useMemo(() => {
    const placeholderTemplate = (
      <span className="block text-gray-500 dark:text-black-10 truncate">{placeholder || t('makeAChoice')}</span>
    );
    if (Array.isArray(selectedItem)) {
      if (selectedItem.length) {
        return (
          <div className="flex flex-wrap gap-2">
            {selectedItem.map((option) => {
              const optionObj = options.find((item) => item.value === option);

              const tagClasses = clsx('flex justify-between items-center rounded-md border border-gray-200', {
                'text-base py-1 px-2': gsize === 'regular',
                'text-xs px-1': gsize === 'small',
              });

              return (
                <div key={option} className={tagClasses}>
                  <span>{optionObj?.component || optionObj?.label}</span>
                  <button
                    className="ml-2"
                    onClick={(ev) => {
                      ev.stopPropagation();
                      onRemoveItem(option);
                    }}
                  >
                    <X />
                  </button>
                </div>
              );
            })}
          </div>
        );
      }

      return placeholderTemplate;
    }

    const optionObj = options.find((item) => item.value === selectedItem);

    return optionObj?.component || optionObj?.label || placeholderTemplate;
  }, [selectedItem, options, placeholder, gsize, onRemoveItem]);

  useEffect(() => {
    if (value && value !== selectedItem) {
      setSelected(value);
    }
  }, [value, selectedItem]);

  const onChangeSelected = useCallback(
    (option: string | string[] | null) => {
      const newOption = selectedItem === option ? [] : option;

      setSelected(newOption);
      triggerHapticFeedback(HapticFeedbackType.IMPACT_MEDIUM);
      onChange({
        target: {
          value: newOption,
        },
      });
    },
    [onChange, selectedItem],
  );

  return (
    <Listbox value={selectedItem} multiple={multiple} disabled={disabled} onChange={onChangeSelected}>
      {({ open }) => (
        <div className="relative">
          {title && (
            <Listbox.Label className="block text-typo-secondary contrast:text-typo-secondary dark:text-black-10 mb-1">
              {title}
              {required && <span className="inline-block ml-1 text-primary-600">*</span>}
              {!required && optionalLabel && <span className="inline-block ml-1">({t('optional').toLowerCase()})</span>}
            </Listbox.Label>
          )}
          <Listbox.Button
            as="div"
            ref={refs.setReference}
            tabIndex={0}
            role="button"
            className={twMerge(btnClasses, open && 'shadow-inputFocus border-primary-300', className)}
          >
            {getSelectedComponent}
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-typo-secondary contrast:text-typo-secondary dark:text-black-10"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <FloatingPortal>
            <Transition
              as={Fragment}
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options ref={refs.setFloating} style={floatingStyles} className={optionsClasses}>
                {options.map((option, optionIdx) => {
                  const selected = isSelected(option.value);

                  return (
                    <Listbox.Option
                      key={optionIdx}
                      className={({ active }) => getOptionClasses({ active, selected, gsize })}
                      value={option.value}
                    >
                      <div className="flex justify-between items-center">
                        <span className={`block truncate ${selected ? 'font-median' : 'font-normal'}`}>
                          {option.component || option.label}
                        </span>
                        {selected && (
                          <span className="flex items-center pl-2 text-primary">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </div>
                    </Listbox.Option>
                  );
                })}
              </Listbox.Options>
            </Transition>
          </FloatingPortal>
          {(error || helperText) && (
            <div className="space-y-1 mt-2">
              {error && <div className="text-error">{error}</div>}
              {helperText && <div className="text-gray-600 text-sm">{helperText}</div>}
            </div>
          )}
        </div>
      )}
    </Listbox>
  );
};

export const optionsClasses =
  'mt-1 max-h-60 min-w-[180px] overflow-auto rounded-md bg-white dark:bg-black-100 p-1 text-base shadow-lg focus:outline-none ring-1 ring-gray-200 space-y-1 z-[9999]';

export const getOptionClasses = ({ active, selected, gsize }: { active: boolean; gsize: Size; selected: boolean }) =>
  clsx('relative rounded-md cursor-pointer select-none', {
    'bg-gray-50': active || selected,
    'py-3 pl-4 pr-4 text-base': gsize === 'regular',
    'py-2 pl-2 pr-2 text-sm': gsize === 'small',
  });
