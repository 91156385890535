export enum HapticFeedbackType {
  IMPACT_LIGHT = 'impactLight',
  IMPACT_MEDIUM = 'impactMedium',
  IMPACT_HEAVY = 'impactHeavy',
  NOTIFICATION_SUCCESS = 'notificationSuccess',
  NOTIFICATION_WARNING = 'notificationWarning',
  NOTIFICATION_ERROR = 'notificationError',
}

/**
 * Triggers haptic feedback on the native side.
 *
 * @param type The type of haptic feedback to trigger.
 */
export const triggerHapticFeedback = (type: HapticFeedbackType) => {
  if (!window['ReactNativeWebView']) {
    return;
  }

  const payload = {
    type: 'HAPTIC',
    payload: { type },
  };
  window['ReactNativeWebView'].postMessage(JSON.stringify(payload));
};

/**
 * Sets the language of the application in the native side and saves this in app storage
 *
 * @param countryCode The country code of the language to set.
 */
export const setNativeLanguage = (countryCode: string) => {
  if (!window['ReactNativeWebView']) {
    return;
  }

  const payload = {
    type: 'SET_LANGUAGE',
    payload: countryCode,
  };

  window['ReactNativeWebView'].postMessage(JSON.stringify(payload));
};
