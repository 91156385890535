import { CheckCircleIcon } from '@heroicons/react/20/solid';

export const PaymentCompleteView = () => {
  return (
    <div className="flex justify-center items-center h-screen w-screen">
      <div className="relative flex items-center flex-col space-y-12">
        <CheckCircleIcon className="text-typo-positive contrast:text-contrast-typo-positive w-16 h-16" />
        <div className="text-typo-primary contrast:text-contrast-typo-primary text-center font-median text-4xl lg:text-5xl">
          We've received
          <br />
          your payment
        </div>
        <svg
          className="absolute bottom-0 left-0 -translate-x-1/2 translate-y-1/2"
          width="270"
          height="270"
          viewBox="0 0 1073 868"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_187_756)">
            <ellipse
              cx="528.773"
              cy="547.077"
              rx="143.729"
              ry="146.561"
              transform="rotate(9.8937 528.773 547.077)"
              fill="url(#paint0_radial_187_756)"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_187_756"
              x="-15.0639"
              y="0.575195"
              width="1087.67"
              height="1093"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="200" result="effect1_foregroundBlur_187_756" />
            </filter>
            <radialGradient
              id="paint0_radial_187_756"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(360.899 505.729) rotate(31.5631) scale(228.833 224.411)"
            >
              <stop offset="0.380208" stopColor="#E8CBFF" />
              <stop offset="1" stopColor="#F6B1E3" />
            </radialGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
};
