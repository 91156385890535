import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getPaymentStatus, startUserInvoicePayment } from '@/business/endpoints';
import { handleValidationError, isApp } from '@/common/utils';
import { PaymentStatus } from '@/web/types';
import React, { useEffect, useState } from 'react';
import { useBusiness } from '@/web/hooks/use-business';
import { useSnack } from '@/common/hooks/use-snack';
import SpinnerIcon from '@/common/components/icon/icons/v2/spinner.svg?react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import { FETCH_USER_INVOICES } from '@/web/views/profile/invoices';
import { FETCH_BUSINESS_CHECKIN_INFO } from '@/web/views/qr-code-view';

type Props = {
  invoiceId: string;
};

const FETCH_INVOICE_PAYMENT_STATUS = 'FETCH_INVOICE_PAYMENT_STATUS';

export const InvoicePayment = ({ invoiceId }: Props) => {
  const { t } = useTranslation();
  const { businessUuid } = useBusiness();
  const { errorMessage } = useSnack();
  const [paymentLink, setPaymentLink] = useState<string | null>(null);
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus | null>(null);
  const [paymentToken, setPaymentToken] = useState<string | null>(null);
  const queryClient = useQueryClient();

  useQuery(
    [FETCH_INVOICE_PAYMENT_STATUS, invoiceId],
    async () => {
      if (!paymentToken) {
        return;
      }

      const response = await getPaymentStatus(paymentToken);
      setPaymentStatus(response.status);

      if (response.status === PaymentStatus.SUCCESS) {
        await Promise.all([
          queryClient.invalidateQueries(FETCH_USER_INVOICES),
          queryClient.invalidateQueries(FETCH_BUSINESS_CHECKIN_INFO),
        ]);
      }
    },
    {
      enabled: paymentToken !== null && paymentStatus === PaymentStatus.PENDING,
      refetchInterval: 2000,
    },
  );

  const { mutate: startPayment } = useMutation(async () => {
    if (!businessUuid) {
      return null;
    }

    setPaymentStatus(PaymentStatus.PENDING);
    let response;

    try {
      response = await startUserInvoicePayment(businessUuid, invoiceId);
    } catch (e) {
      handleValidationError(e, undefined, errorMessage);
      return;
    }

    setPaymentLink(response.paymentLink);
    setPaymentStatus(PaymentStatus.PENDING);

    if (isApp()) {
      window.open(response.paymentLink, '_self');
    } else {
      // Trick to open in a new tab without popup blocker.
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.href = response.paymentLink;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    setPaymentToken(response.paymentStatusToken);
  });

  useEffect(() => {
    startPayment();
  }, [startPayment]);

  return (
    <div className="flex flex-col items-center space-y-5 py-5">
      <div className="font-medium text-typo-primary text-base">
        {paymentStatus === PaymentStatus.PENDING && t('paymentInProgress')}
        {paymentStatus === PaymentStatus.SUCCESS && t('paymentComplete')}
      </div>
      {paymentStatus === PaymentStatus.PENDING && <SpinnerIcon className="w-16 h-16 animate-spin" />}
      {paymentStatus === PaymentStatus.SUCCESS && <CheckCircleIcon className="w-16 h-16 text-typo-positive" />}
      <div className="text-base text-typo-secondary">
        {paymentStatus === PaymentStatus.PENDING && isApp() && t('paymentWaitingConfirmation')}
        {paymentStatus === PaymentStatus.PENDING && !isApp() && paymentLink && (
          <div className="text-center">
            {t('redirectFallback.text')}{' '}
            <a href={paymentLink} target="_blank" rel="noreferrer noopener" className="underline">
              {t('redirectFallback.action')}
            </a>
            .
          </div>
        )}
        {paymentStatus === PaymentStatus.SUCCESS && t('paymentConfirmationMail')}
      </div>
    </div>
  );
};
