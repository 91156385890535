import { Title } from '@/design-system/v3/title';
import desktopAlert from '@/web/views/desktop-alert/desktop-alert-1.png';
import desktopAlert2 from '@/web/views/desktop-alert/desktop-alert-2.png';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { FETCH_BUSINESS, getBusiness, getBusinessCustomAppConfiguration } from '@/business/endpoints';
import { GLoader } from '@/design-system/g-loader';

const FETCH_CUSTOM_APP_CONFIGURATION = 'FETCH_CUSTOM_APP_CONFIGURATION';

export const OnboardingComplete = () => {
  const { id } = useParams<{ id: string | null }>();

  const { data: business, isLoading: isLoadingBusiness } = useQuery([FETCH_BUSINESS, id], async () => {
    if (!id) return null;

    return getBusiness(id);
  });

  const { data: customAppConfiguration, isLoading: isLoadingCustomAppConfiguration } = useQuery(
    [FETCH_CUSTOM_APP_CONFIGURATION, business?.id],
    async () => {
      if (!business) {
        return;
      }

      return getBusinessCustomAppConfiguration(business.id);
    },
  );

  if (id && (isLoadingBusiness || isLoadingCustomAppConfiguration)) {
    return (
      <div className="flex h-screen w-screen justify-center items-center">
        <GLoader variant="secondary" />
      </div>
    );
  }

  if (business && customAppConfiguration) {
    return (
      <div className="h-screen w-screen flex flex-col items-center justify-center text-center">
        <div className="relative z-10 pt-[80px] w-full md:w-auto flex flex-col items-center justify-center -mt-[120px]">
          <div className="flex justify-center w-full mb-6">
            <img src={business.publicLogoUrl} className="max-h-[96px] w-auto self-start" alt="logo" />
          </div>
          <Title className="text-5xl mb-6 leading-[60px]">Onboarding complete!</Title>
          <p className="max-w-[400px]">
            To continue, download the {business.name} app from the{' '}
            <a href={customAppConfiguration.appleAppStoreUrl}>Apple Store</a> or{' '}
            <a href={customAppConfiguration.googlePlayStoreUrl}>Google Play</a>.
          </p>
          <div className="flex flex-row items-center space-x-4 mt-8">
            <a href={customAppConfiguration.appleAppStoreUrl}>
              <img src="/applebadge.png" alt="Download on the App Store" className="h-12 w-auto" />
            </a>
            <a href={customAppConfiguration.googlePlayStoreUrl}>
              <img src="/googlebadge.png" alt="Get it on Google Play" className="h-12 w-auto" />
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen w-screen flex flex-col items-center text-center">
      <div className="pt-[80px] w-full md:w-auto flex flex-col items-center justify-center">
        <Title className="text-5xl mb-6 leading-[60px]">Onboarding complete!</Title>
        <p className="max-w-[400px]">
          To continue, download the Gymly app from the{' '}
          <a href="https://apps.apple.com/nl/app/gymly/id1635326565">Apple Store</a> or{' '}
          <a href="https://play.google.com/store/apps/details?id=io.gymly.app&hl=en&pli=1">Google Play</a>, or visit{' '}
          <a href="https://app.gymly.io">app.gymly.io</a> on your mobile browser.
        </p>
        <div className="flex flex-row items-center space-x-4 mt-8">
          <a href="https://apps.apple.com/nl/app/gymly/id1635326565">
            <img src="/applebadge.png" alt="Download on the App Store" className="h-12 w-auto" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=io.gymly.app&hl=en&pli=1">
            <img src="/googlebadge.png" alt="Get it on Google Play" className="h-12 w-auto" />
          </a>
        </div>
      </div>
      <div className="absolute bottom-0 max-w-[420px] w-full overflow-hidden sm:overflow-visible">
        <img src={desktopAlert} className="max-h-[40vh] max-w-[260px] relative z-10" alt="iphone screenshot" />
        <img
          src={desktopAlert2}
          className="max-h-[40vh] max-w-[280px] absolute left-[150px] md:left-[200px] bottom-0 z-[5]"
          alt="iphone screenshot 2"
        />
        <div
          className="w-[290px] h-[290px] rounded-full absolute z-0 top-[60px] -left-[90px]"
          style={{
            background: 'radial-gradient(76.98% 79.19% at -8.4% 35.89%, #E8CBFF 38.02%, #F6B1E3 100%)',
            filter: 'blur(200px)',
          }}
        />
      </div>
    </div>
  );
};
