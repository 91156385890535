import Button from '@/design-system/v3/button';
import Text from '@/design-system/v3/text';
import { Title } from '@/design-system/v3/title';
import { WifiX } from 'phosphor-react';
import { ReactNode, useEffect, useState } from 'react';

export const OfflineScreen = ({ children }: { children: ReactNode }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const goOnline = () => setIsOnline(true);
    const goOffline = () => setIsOnline(false);

    window.addEventListener('online', goOnline);
    window.addEventListener('offline', goOffline);

    return () => {
      window.removeEventListener('online', goOnline);
      window.removeEventListener('offline', goOffline);
    };
  }, []);

  if (!isOnline) {
    return (
      <div className="h-screenHeight justify-center flex items-center p-4">
        <div className="text-center flex flex-col items-center justify-center">
          <Title>Offline</Title>
          <Text className="text-typo-secondary contrast:text-contrast-typo-secondary mt-2">
            You seem to be offline, please check your internet connection
          </Text>
          <WifiX size={68} className="text-typo-secondary/30 contrast:text-contrast-typo-secondary/30 my-10" />
          <Button variant="secondary" className="w-auto" onClick={() => window.location.reload()}>
            Refresh
          </Button>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};
