import { CourseDetail, CourseDetailWithHelpers, PaymentSession, PaymentStatus } from '@/web/types';
import { formatAmount, handleValidationError, isApp, parseCourseISO } from '@/common/utils';
import Button from '@/design-system/v3/button';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  FETCH_COURSE_DETAIL_QUERY,
  FETCH_COURSE_MEMBERS_QUERY,
  FETCH_UPCOMING_COURSES,
  FETCH_USER,
  purchaseEvent,
} from '@/web/endpoints';
import { FETCH_WAITLIST, getPaymentStatus } from '@/business/endpoints';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useBusiness } from '@/web/hooks/use-business';
import { useSnack } from '@/common/hooks/use-snack';
import React, { useState } from 'react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import SpinnerIcon from '@/common/components/icon/icons/v2/spinner.svg?react';
import { format } from 'date-fns';
import { CourseJoinSpots } from '@/common/components/booking-flow/course-spots';
import TagIcon from '@/common/components/icon/icons/v2/tag.svg?react';
type Props = {
  event: CourseDetailWithHelpers;
  onClose: () => void;
};

const FETCH_EVENT_PAYMENT_STATUS = 'FETCH_EVENT_PAYMENT_STATUS';

export const EventPurchaseModal = ({ event, onClose }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { businessUuid } = useBusiness();
  const { errorMessage } = useSnack();
  const [paymentSession, setPaymentSession] = useState<PaymentSession | null>(null);
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus | null>(null);

  useQuery(
    [FETCH_EVENT_PAYMENT_STATUS, event.id],
    async () => {
      if (!paymentSession) {
        return;
      }

      const response = await getPaymentStatus(paymentSession.paymentStatusToken);
      setPaymentStatus(response.status);

      if (response.status === PaymentStatus.SUCCESS) {
        await Promise.all([
          queryClient.invalidateQueries([FETCH_COURSE_DETAIL_QUERY]),
          queryClient.invalidateQueries([FETCH_COURSE_MEMBERS_QUERY]),
          queryClient.invalidateQueries([FETCH_WAITLIST]),
          queryClient.invalidateQueries([FETCH_USER]),
          queryClient.invalidateQueries([FETCH_UPCOMING_COURSES]),
        ]);
      }
    },
    {
      enabled: paymentSession !== null && paymentStatus === PaymentStatus.PENDING,
      refetchInterval: 2000,
    },
  );

  const { mutate, isLoading } = useMutation(() => purchaseEvent(businessUuid as string, event.id), {
    onSuccess: (session) => {
      if (!session) {
        onClose();

        return Promise.all([
          queryClient.invalidateQueries([FETCH_COURSE_DETAIL_QUERY]),
          queryClient.invalidateQueries([FETCH_COURSE_MEMBERS_QUERY]),
          queryClient.invalidateQueries([FETCH_WAITLIST]),
          queryClient.invalidateQueries([FETCH_USER]),
          queryClient.invalidateQueries([FETCH_UPCOMING_COURSES]),
        ]);
      }

      setPaymentStatus(PaymentStatus.PENDING);
      setPaymentSession(session);

      // Open link in new window
      if (isApp()) {
        window.open(session.paymentLink, '_self');
      } else {
        // Trick to open in a new tab without popup blocker.
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.href = session.paymentLink;
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },
    onError: (e: AxiosError<{ code: string }>) => {
      handleValidationError(e, undefined, errorMessage);
    },
  });

  if (paymentSession) {
    return (
      <div className="flex flex-col justify-center items-center">
        <div className="font-medium text-typo-primary text-base">
          {paymentStatus === PaymentStatus.PENDING && t('paymentInProgress')}
          {paymentStatus === PaymentStatus.SUCCESS && t('paymentComplete')}
        </div>
        {paymentStatus === PaymentStatus.PENDING && <SpinnerIcon className="w-16 h-16 animate-spin" />}
        {paymentStatus === PaymentStatus.SUCCESS && <CheckCircleIcon className="w-16 h-16 text-typo-positive" />}
        <div className="text-base text-typo-secondary">
          {paymentStatus === PaymentStatus.PENDING && isApp() && t('paymentWaitingConfirmation')}
          {paymentStatus === PaymentStatus.PENDING && !isApp() && (
            <div className="text-center">
              {t('redirectFallback.text')}{' '}
              <a href={paymentSession.paymentLink} target="_blank" rel="noreferrer noopener" className="underline">
                {t('redirectFallback.action')}
              </a>
              .
            </div>
          )}
          {paymentStatus === PaymentStatus.SUCCESS && t('paymentConfirmationMail')}
        </div>
        {paymentStatus === PaymentStatus.SUCCESS && (
          <Button variant="primary" onClick={onClose} className="mt-8">
            {t('done')}
          </Button>
        )}
      </div>
    );
  }

  const fallbackPrice = event.price ? event.price : event.detailedActivities[0].options.price;

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex justify-center items-center font-semibold">
        <span className="text-base">{t('confirmAndPay')}</span>
      </div>
      <div className="flex flex-col border border-gray-200 rounded-md">
        <div className="flex flex-row items-center justify-between border-b border-gray-200 p-3">
          <div className="flex flex-col">
            <span className="font-semibold">{event.name}</span>
            {event.teachers && <span className="text-gray">{event.teachers.map((t) => t.fullName).join(', ')}</span>}
          </div>
          <div>
            {event.price && event.userPrice && (
              <>
                {event.price !== event.userPrice && event.userPrice && (
                  <>
                    {formatAmount(event.userPrice, 'EUR')}{' '}
                    <span className="line-through opacity-60">{formatAmount(event.price, 'EUR')}</span>
                  </>
                )}
                {event.price === event.userPrice && event.price && formatAmount(event.price, 'EUR')}
              </>
            )}
            {!event.price && fallbackPrice && formatAmount(fallbackPrice, 'EUR')}
          </div>
        </div>
        <div className="flex flex-row items-center justify-between border-b border-gray-200 p-3">
          <div className="text-gray">{t('location')}</div>
          <div>{event.businessLocation.name}</div>
        </div>
        <div className="flex flex-row items-center justify-between p-3">
          <div className="text-gray">{t('date')}</div>
          <div>{format(parseCourseISO(event.startAt, event), 'MMM dd yyyy, HH:mm')}</div>
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="flex flex-col space-y-1">
          <Button variant="primary" onClick={mutate} loading={isLoading}>
            {t('confirmAndPay')} • {formatAmount((event.userPrice ?? fallbackPrice) as number, 'EUR')}
          </Button>
          {event.discount && (
            <div className="flex items-center justify-center w-full bg-gray-50 text-gray-700 rounded-md py-1 px-2 space-x-1">
              <TagIcon className="w-5 h-5" />
              <span>{t('discountApplied', { amount: event.discount.amountFormatted })}</span>
            </div>
          )}
        </div>
        <Button variant="secondary" onClick={onClose} disabled={isLoading}>
          {t('cancel')}
        </Button>
      </div>
      <div className="flex items-center justify-center">
        <CourseJoinSpots spots={event.capacity - event.memberCount} capacity={event.capacity} />
      </div>
    </div>
  );
};
