import { GInput } from '@/design-system/v3/g-input';
import SearchIcon from '@/icons/search.svg?react';
import PlusIcon from '@/icons/plus.svg?react';
import CheckIcon from '@/icons/check.svg?react';
import { useDebounce } from '@/common/hooks/use-debounce';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { FETCH_BUSINESS_MEMBERS } from '@/business/endpoints';
import { useBusiness } from '@/web/hooks/use-business';
import { useQuery, useQueryClient } from 'react-query';
import {
  addAttendeeToCourse,
  FETCH_COURSE_DETAIL_QUERY,
  FETCH_COURSE_MEMBERS_QUERY,
  getBusinessUsers,
} from '@/web/endpoints';
import { GLoader } from '@/design-system/g-loader';
import { GAvatar } from '@/design-system/g-avatar';
import Button from '@/design-system/v3/button';
import { useTranslation } from 'react-i18next';
import { handleValidationError, highlightQuery } from '@/common/utils';
import { useSnack } from '@/common/hooks/use-snack';
import { useCourseMembers } from '@/web/views/course-detail';
import PeopleAddIcon from '@/icons/people-add.svg?react';
import clsx from 'clsx';
import { CourseOptionsMenuContext } from '@/web/views/course-detail/course-options-menu/context';

export const AddMemberMode = () => {
  const { t } = useTranslation();
  const { businessUuid } = useBusiness();
  const { errorMessage } = useSnack();
  const { course, courseDate } = useContext(CourseOptionsMenuContext);
  const queryClient = useQueryClient();
  const [query, setQuery] = useState<string>('');
  const debouncedQuery = useDebounce(query, 500) as string;
  const { members } = useCourseMembers();
  const [loading, setLoading] = useState<Record<string, boolean>>({});

  const { data: users, isLoading } = useQuery([FETCH_BUSINESS_MEMBERS, businessUuid, debouncedQuery], async () => {
    if (businessUuid && debouncedQuery.length >= 3) {
      return getBusinessUsers(businessUuid, {
        page: 1,
        size: 10,
        query: debouncedQuery,
      });
    }
  });

  const memberIds = useMemo(() => {
    return members?.map((member) => member.id) || [];
  }, [members]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleAddAttendee = useCallback(
    (id: string) => async () => {
      if (!businessUuid) {
        return;
      }

      setLoading((prev) => ({
        ...prev,
        [id]: true,
      }));

      try {
        await addAttendeeToCourse(course.id, courseDate.toISOString(), id);
      } catch (e) {
        handleValidationError(e, undefined, errorMessage);
        return;
      }

      await Promise.all([
        queryClient.invalidateQueries([FETCH_COURSE_MEMBERS_QUERY, course.id]),
        queryClient.invalidateQueries([FETCH_COURSE_DETAIL_QUERY, course.id]),
      ]);

      setLoading((prev) => ({
        ...prev,
        [id]: false,
      }));
    },
    [course, courseDate, errorMessage, queryClient, businessUuid],
  );

  return (
    <div className="flex flex-col space-y-3">
      <div className="px-4">
        <GInput
          icon={<SearchIcon />}
          gsize="large"
          iconContainerClasses="bg-gray-50"
          className="sticky top-0 bg-gray-50"
          onChange={handleSearch}
        />
      </div>
      {(!users || isLoading) && (
        <div
          className={clsx(
            'flex justify-center items-center min-h-[240px] px-4 mx-4 border border-gray-200 rounded-lg',
            {
              'items-center': !users,
            },
          )}
        >
          {!users && !isLoading && (
            <div className="flex flex-col space-y-3 justify-center items-center font-neutral p-5">
              <PeopleAddIcon className="text-secondary h-8 w-8" />
              <span>{t('courseOptionsMenu.addMember.hint')}</span>
            </div>
          )}
          {isLoading && <GLoader variant="secondary" />}
        </div>
      )}
      {users && !isLoading && (
        <div className="flex flex-col w-full h-full max-h-[calc(95vh-148px)] overflow-y-scroll">
          {users.content.map((user) => (
            <div
              key={user.id}
              className="flex justify-between items-center border-b border-gray-200 first:border-t first:pt-2.5 pb-2.5 px-4 mb-2.5 last:mb-0 last:border-b-0"
            >
              <div className="flex items-center space-x-2">
                <GAvatar key={user.id} size="2xs" path={user.profileImageUrl} />
                <div className="flex flex-col">
                  <span>{highlightQuery(user.fullName, debouncedQuery)}</span>
                  <span className="text-xs text-typo-secondary contrast:text-contrast-typo-secondary">
                    {user.email}
                  </span>
                </div>
              </div>
              <div>
                <Button
                  variant="secondary"
                  onClick={handleAddAttendee(user.id)}
                  loading={loading[user.id] ?? false}
                  disabled={memberIds.includes(user.id)}
                >
                  {memberIds.includes(user.id) && <CheckIcon className="w-6 h-6" />}
                  {!memberIds.includes(user.id) && <PlusIcon className="w-6 h-6" />}
                </Button>
              </div>
            </div>
          ))}
          <div className="flex flex-col px-4 justify-center text-center text-xs text-typo-secondary contrast:text-contrast-typo-secondary">
            <span>
              {t('pagination.results', {
                amount: users.content.length,
                total: users.totalElements,
              })}
            </span>
            {users.totalPages > 1 && <span>{t('pagination.refineHint')}</span>}
          </div>
        </div>
      )}
    </div>
  );
};
