import MembershipItem from './MembershipItem';
import { MembershipStatus } from '../../enums/MembershipStatus.enum';
import { useBusinessUserMemberships } from '@/features/memberships/hooks/use-business-user-memberships';

const MembershipList = () => {
  const businessMemberships = useBusinessUserMemberships();

  return (
    <div className="divide-y divide-gray-200 border-y border-gray-200">
      {businessMemberships.map((membership) => {
        let status: MembershipStatus = MembershipStatus.ACTIVE;

        if (membership.paused) {
          status = MembershipStatus.PAUSED;
        } else if (membership.awaitingPayment) {
          status = MembershipStatus.AWAITING_PAYMENT;
        } else if (membership.activeAt === null) {
          status = membership.activationStrategy === 'FIRST_USE' ? MembershipStatus.PENDING : MembershipStatus.INACTIVE;
        }

        return (
          <MembershipItem
            key={membership.id}
            membership={{
              ...membership,
              status,
            }}
          />
        );
      })}
    </div>
  );
};

export default MembershipList;
